import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Route,
    UrlSegment,
    CanActivateFn,
    CanMatchFn
} from "@angular/router";
import { tap, take } from "rxjs/operators";
import { Auth0AuthorizationService } from "./auth0-authorization.service";

export const canActivateAuth0Guard: CanActivateFn = (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const auth = inject(Auth0AuthorizationService);

    return auth.isAuthenticated$.pipe(
        take(1),
        tap(loggedIn => {
            if (!loggedIn) {
                auth.login(state.url);
            }
        })
    );
};

export const canMatchAuth0Guard: CanMatchFn = (_route: Route, segments: UrlSegment[]) => {
    const auth = inject(Auth0AuthorizationService);

    return auth.isAuthenticated$.pipe(
        take(1),
        tap(loggedIn => {
            if (!loggedIn) {
                const fullPath = segments.reduce((path, currentSegment) => {
                    return `${path}/${currentSegment.path}`;
                }, "");

                if (fullPath !== "/callback") {
                    auth.login(fullPath);
                }
            }
        })
    );
};
