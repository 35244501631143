import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Dictionary } from "lodash";
import { LOCALE_ID, Injectable, inject } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";
import { SurveyAppState } from "./survey-app-state.service";

@Injectable()
export class SurveyLocalizationSettings implements LgLocalizationSettings {
    private _locale = inject(LOCALE_ID);

    readonly bootstrapDone = Promise.resolve();

    readonly languages: _.Dictionary<any[]> = {};

    private _preferredLanguage;

    constructor() {
        const preferredLanguage = localStorage.getItem("preferredLanguage");
        if (preferredLanguage != null && this.availableLanguages.includes(preferredLanguage)) {
            this._preferredLanguage = preferredLanguage;
        } else {
            this._preferredLanguage = this._locale;
        }
    }

    get fallbackLanguage(): string {
        return "en";
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return ["en-GB", "nl-NL"];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    switchCurrentLanguage(lang: string): void {
        localStorage.setItem("preferredLanguage", lang);
        window.location.reload();
    }

    addStrings(lang: string, strings: Dictionary<any>): void {
        this.languages[lang] = [...(this.languages[lang] ?? []), strings];
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;
    }

    initAutomaticSwitch(): void {
        const appState = inject(SurveyAppState);

        appState.currentProject$.pipe(takeUntilDestroyed()).subscribe(current => {
            const project = current?.definition;

            if (project?.defaultLanguage == null || project.defaultLanguage.length === 0) {
                return;
            }

            const parsedLang = this.availableLanguages.find(lang =>
                lang.includes(project.defaultLanguage!)
            );
            if (parsedLang !== undefined && parsedLang !== this.preferredLanguage) {
                requestAnimationFrame(() => this.switchCurrentLanguage(parsedLang));
            }
        });
    }
}
