import { HttpContextToken } from "@angular/common/http";
import { InjectionToken } from "@angular/core";

export enum SurveyErrorCode {
    NotLoggedIn = 1,
    NotAuthorized = 2,
    NoAccess = 3,
    ProjectNotAvailable = 4,
    Generic = 99
}

export interface SurveyError {
    code: SurveyErrorCode;
    error: string;
    statusCode?: number;
    response?: any;
    handled?: boolean;
}

export const SURVEY_REST_API_URL = new InjectionToken<string>("SurveyRestApiUrl");
export const SAVE_PROJECT = new HttpContextToken<boolean>(() => false);
