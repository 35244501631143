import { OptionVisibility } from "./option-visibility";
import { OptionDefinition } from "./option-definition";
import { ExternalOptionsetDefinition } from "./external-optionset-definition";

export interface OptionsetDefinition {
    name: string;
    optionVisibility: OptionVisibility | null;
    external: boolean;
    searchLabel: string | null;
    options: OptionDefinition[] | null;
    externalDefinition: ExternalOptionsetDefinition | null;
}
