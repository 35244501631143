import { LayoutButtonDefinition } from "./layout-button-definition";
import { OptionVisibility } from "./option-visibility";

export interface LayoutDefinition {
    columns: number;
    previousSection: boolean;
    nextSection: boolean;
    optionVisibility: OptionVisibility;
    addSubject: boolean;
    addCopy: boolean;
    buttons: null | LayoutButtonDefinition[];
}
