import { Injectable } from "@angular/core";
import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    MatomoStatsUrl
} from "@logex/framework/lg-application";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class MatomoConfiguration implements IMatomoConfiguration {
    statUrl: string;
    siteId!: number; // this is a lie, but we need to improve fw typing first
    commonDimensionIds: IMatomoCommonDimensionIds;
    customDimensions = (): IMatomoDimension[] => this._getCustomDimensions();
    autoInit: boolean;
    autoTrack: boolean;

    private _userPosition: string | undefined;

    constructor(private _appConfiguration: ConfigService) {
        this.statUrl = MatomoStatsUrl.MRDM;
        this.commonDimensionIds = {} as any;
        this.autoInit = true;
        this.autoTrack = true;

        this._appConfiguration.configurationPromise.then(val => {
            this.siteId = val.matomo.siteId;
        });
    }

    doNotDoMatTracking(): boolean {
        return this._appConfiguration.getDoNotTrack();
    }

    setUserPosition(position: string | undefined): void {
        this._userPosition = position;
    }

    _getCustomDimensions(): IMatomoDimension[] {
        const result: IMatomoDimension[] = [];
        if (this._userPosition) {
            result.push({
                id: this._appConfiguration.configuration!.matomo.positionDimensionId,
                value: this._userPosition
            });
        }
        return result;
    }
}
