export const enum VariableValidationType {
    Text = "text",
    Error = "error",
    Exception = "exception",
    Warning = "warning",
    Hidden = "hidden"
}

export interface VariableValidation {
    name: string;
    type: VariableValidationType;
    message: string;
}
