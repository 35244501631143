{{ ".Results_page_page" | lgTranslate }}:&nbsp;
<ng-container *ngFor="let size of _sizes; let last = last">
    <a
        *ngIf="size !== _pageSize"
        (click)="_savePageSize(size)"
        [routerLink]="['.']"
        [queryParamsHandling]="'merge'"
        [queryParams]="_getParams(size)"
    >
        {{ size === 0 ? ("APP._Common.Paging_all" | lgTranslate) : size }}
    </a>
    {{ size === _pageSize ? (size === 0 ? ("APP._Common.Paging_all" | lgTranslate) : size) : "" }}
    {{ last ? "" : " | " }}
</ng-container>
