import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Attribute,
    OnDestroy
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { SurveyAppState } from "../../services";

@Component({
    selector: "survey-page-size",
    templateUrl: "./survey-page-size.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyPageSizeComponent implements OnDestroy {
    _sizes: number[] = [];
    _pageSize = 1;
    readonly _parameterName: string = "pageSize";
    private readonly _destroyed$ = new Subject<void>();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _appState: SurveyAppState,
        // eslint-disable-next-line @angular-eslint/no-attribute-decorator
        @Attribute("parameterName") parameterName: string
    ) {
        if (parameterName) this._parameterName = parameterName;

        this._route.queryParamMap.pipe(takeUntil(this._destroyed$)).subscribe(params => {
            let pageSize = this._appState.getValidPageSize(params.get(this._parameterName));
            if (pageSize === null) {
                pageSize = this._appState.getPagingSize();
            }
            this._pageSize = pageSize;
            this._buildList();
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _getParams(size: number): any {
        return {
            [this._parameterName]: size
        };
    }

    _savePageSize(size: number): void {
        this._appState.setPagingSize(size);
    }

    private _buildList(): void {
        this._sizes = [10, 50, 100];
        if (this._pageSize !== 0 && this._sizes.indexOf(this._pageSize) === -1) {
            this._sizes.push(this._pageSize);
            this._sizes.sort((a, b) => a - b);
        }
        // this._sizes.push(0);
    }
}
