import _ from "lodash";

export interface ExternalOptionsetColumn {
    id: string;
    label: string;
}

export interface ExternalOptionsetRow {
    id: string;
    variables: _.Dictionary<string>;
}

export interface ExternalOptionset {
    columns: ExternalOptionsetColumn[];
    rows: ExternalOptionsetRow[];
}
