import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { canActivateAuth0Guard, canMatchAuth0Guard } from "survey-authorization";

import { AccessDeniedComponent } from "./components/access-denied/access-denied.component";

const routes: Routes = [
    {
        path: "access-denied",
        component: AccessDeniedComponent
    },
    {
        path: "",
        loadChildren: () => import("./authorized/authorized.module").then(m => m.AuthorizedModule),
        canMatch: [canMatchAuth0Guard],
        canActivate: [canActivateAuth0Guard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
