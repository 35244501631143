import _ from "lodash";

export type ValidatePotentialSubjectResponse =
    | {
          status: "ok";
      }
    | {
          status: "error";
          messages: _.Dictionary<string[]>;
      };
