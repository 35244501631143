import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { SurveyEnvironmentWidgetComponent, SurveySidebarUserComponent } from "./sidebar/index";

const declarations = [SurveyEnvironmentWidgetComponent, SurveySidebarUserComponent];

import { LgLayoutModule } from "@logex/framework/lg-layout";

@NgModule({
    imports: [CommonModule, UiCoreModule, LgLocalizationModule, LgLayoutModule],
    exports: [...declarations],
    declarations: [...declarations]
})
export class UiSurveyModule {}
