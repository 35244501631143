import _ from "lodash";
import {
    DatasetDefinition,
    ProjectDefinition,
    ProjectOverrides,
    ProjectResources,
    SectionDefinition,
    SectionDefinitionOverride,
    VariableDefinition,
    VariableDefinitionOverride
} from "../../models";

function extendDatasetVariables(
    dataset: DatasetDefinition,
    variableOverrides: _.Dictionary<VariableDefinitionOverride>,
    resources?: ProjectResources
): void {
    const lookup: _.Dictionary<VariableDefinition> = {};
    // This is duplicate of the code in the UI, but.. let's keep the concerns separate
    // (and one day, hopefully, the extension mechanism will be gone)
    for (const section of dataset.sections) {
        for (const variable of section.variables) {
            lookup[variable.name] = variable;
        }
    }
    Object.keys(variableOverrides).forEach(id => {
        const target = lookup[id];
        if (!target) {
            console.error("Override: unknown variable " + id);
            return;
        }

        const src = variableOverrides[id];
        if (src.localizations) {
            target.localizations = {
                ...target.localizations,
                ...src.localizations
            };
        }

        if (src.optionset) {
            if (!target.optionset) {
                console.error(`Override: variable ${id} doesn't have optionset`);
                return;
            }
            if (src.optionset.options) {
                const optionLookup = _.keyBy(target.optionset.options, option => option.value);
                Object.keys(src.optionset.options).forEach(value => {
                    const targetOption = optionLookup[value];
                    if (!targetOption) {
                        console.error(`Override: unknown option ${value} in variable ${id}`);
                        return;
                    }
                    const srcOption = src.optionset!.options![value];
                    if (srcOption.image) {
                        targetOption.image = {
                            ...srcOption.image,
                            height: srcOption.image.height ?? 80
                        };
                    }
                });
            }
        }

        target.isBarcodeScannerInput = src.isBarcodeScannerInput ?? false;

        if (src.useResourceIcon) {
            target.resourceIcon = resources?.icon?.[src.useResourceIcon] ?? null;
        }

        target.isUnskippable = src.isUnskippable ?? false;
    });
}

function extendDatasetSections(
    dataset: DatasetDefinition,
    sectionsOverrides: _.Dictionary<SectionDefinitionOverride>
): void {
    const lookup: _.Dictionary<SectionDefinition> = {};
    // This is duplicate of the code in the UI, but.. let's keep the concerns separate
    // (and one day, hopefully, the extension mechanism will be gone)
    for (const section of dataset.sections) {
        lookup[section.name] = section;
    }

    Object.keys(sectionsOverrides).forEach(id => {
        const target = lookup[id];

        if (!target) {
            console.error("Override: unknown section " + id);
            return;
        }

        const src = sectionsOverrides[id];
        target.showAddSubjectButton = src.showAddSubjectButton;
    });
}

export function extendProjectDefinition(
    project: ProjectDefinition,
    overrides: ProjectOverrides
): ProjectDefinition {
    project.localizations = { ...project.localizations, ...overrides.localizations };
    project.legacyLabelSplit = overrides.legacyLabelSplit ?? project.legacyLabelSplit;
    project.skipEmptyCreationDialog =
        overrides.skipEmptyCreationDialog ?? project.skipEmptyCreationDialog;
    project.hideSubjectCountInSubjectList =
        overrides.hideSubjectCountInSubjectList ?? project.hideSubjectCountInSubjectList;
    project.reports = overrides.reports ?? project.reports;

    if (overrides.datasets) {
        Object.keys(overrides.datasets).forEach(datasetName => {
            const dataset = project.datasets.find(d => d.name === datasetName);
            if (!dataset) {
                console.warn("Override: unknown dataset " + datasetName);
                return;
            }
            const src = overrides.datasets![datasetName];
            if (src.localizations) {
                dataset.localizations = {
                    ...dataset.localizations,
                    ...src.localizations
                };
            }
            if (src.indelible) {
                dataset.indelible = src.indelible;
            }
            if (src.layoutHideAddSubjectInSections) {
                dataset.layoutHideAddSubjectInSections = src.layoutHideAddSubjectInSections;
            }
            if (src.variables) {
                extendDatasetVariables(dataset, src.variables, overrides.resources);
            }
            if (src.sections) {
                extendDatasetSections(dataset, src.sections);
            }
        });
    }

    return project;
}
