export interface ExternalDatasetEntry {
    uri: string;
    oldUri: string;
    label: string;
}

export interface ExternalDataset {
    total: number;
    section: string;
    entries: ExternalDatasetEntry[];
}
