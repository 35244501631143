import { NgModule, ModuleWithProviders } from "@angular/core";
import { SurveySessionInterceptorService } from "./services";
import { INTERCEPTOR_CHAIN_DEFINITION } from "./utility/index";

@NgModule({
    declarations: [],
    imports: [],
    exports: []
})
export class SurveyApiModule {
    static forRoot(): ModuleWithProviders<SurveyApiModule> {
        return {
            ngModule: SurveyApiModule,
            providers: [
                {
                    provide: INTERCEPTOR_CHAIN_DEFINITION,
                    useValue: {
                        SURVEY_SESSION: [SurveySessionInterceptorService]
                    },
                    multi: true
                }
            ]
        };
    }
}
