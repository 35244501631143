import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { TranslateModule, TranslateCompiler } from "@ngx-translate/core";
import { MarkdownModule } from "ngx-markdown";

import { LgConsoleConfiguration, LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";
import {
    ReferenceTranslateCompiler,
    useMessageFormatLocales,
    LG_LOCALIZATION_SETTINGS,
    LgLocalizationModule
} from "@logex/framework/lg-localization";
import { LgLocalStorageFwUiStateService, LG_FW_UI_STATE_SERVICE } from "@logex/framework/lg-layout";
import {
    LgApplicationModule,
    LgMatTrackingService,
    LgApplicationInsightsService,
    LG_MATOMO_CONFIGURATION,
    LG_APPLICATION_INSIGHTS_CONFIGURATION,
    LG_NAVIGATION,
    LG_USER_INFO,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    LG_APP_INFO,
    LG_USERFLOW_SERVICE,
    LgUserflowService,
    LG_USERFLOW_CONFIGURATION,
    LgUserflowConfiguration,
    LG_AUTHENTICATION_SERVICE
} from "@logex/framework/lg-application";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
    Auth0InterceptorService,
    AUTH0_CONFIG,
    SurveyAuthorizationModule,
    MRDM_AUTH_API_URL,
    Auth0AuthenticationService
} from "survey-authorization";
import { SharedModule } from "./shared/shared.module";
import { UiSurveyModule } from "./ui-survey/ui-survey.module";
import {
    ApplicationInsightsConfigurationService,
    AppStartupService,
    MatomoConfiguration,
    SurveyAppState,
    SurveyLocalizationSettings
} from "./shared";
import {
    SurveyApiModule,
    SURVEY_REST_API_URL,
    SURVEY_VALIDATION_REPORTS_API_URL
} from "survey-api";
import { ConfigService } from "./shared/services/config.service";
import { MetaInterceptorService } from "projects/survey-api/src/lib/utility/meta-interceptor.service";
import { AccessDeniedComponent } from "./components/access-denied/access-denied.component";

@NgModule({
    declarations: [AppComponent, AccessDeniedComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        LgApplicationModule,
        MarkdownModule.forRoot(),
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        SurveyAuthorizationModule.forRoot(),
        SurveyApiModule.forRoot(),
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),

        UiSurveyModule,
        SharedModule
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                // config.logger( "Logex.Directives.LgPivotTableController", ConsoleLogLevel.Error );
                // config.logger( "Logex.Application.ServerGatewayBase", ConsoleLogLevel.Error );
                return config;
            }
        },
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB"]),
        {
            provide: LOCALE_ID,
            useValue: "nl-NL"
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: SurveyLocalizationSettings
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: async (configService: ConfigService) => {
                const configuration = await configService.configurationPromise;
                return configuration.auth0;
            },
            deps: [ConfigService]
        },
        {
            provide: LG_APP_CONFIGURATION,
            useFactory: (configService: ConfigService) => {
                const { userflow } = configService.configuration!;
                return {
                    userflowContentId: userflow.contentId,
                    userflowEnvironmentKey: userflow.environmentKey,
                    ready: Promise.resolve()
                };
            },
            deps: [ConfigService]
        },
        {
            provide: LG_APP_INFO,
            useFactory: () => ({})
        },
        {
            provide: LG_APP_SESSION,
            useFactory: () => ({})
        },
        {
            provide: SURVEY_REST_API_URL,
            useFactory: (config: ConfigService) => config.configuration?.api.url,
            deps: [ConfigService]
        },
        {
            provide: SURVEY_VALIDATION_REPORTS_API_URL,
            useFactory: (config: ConfigService) => config.configuration?.api.validationUrl,
            deps: [ConfigService]
        },
        {
            provide: MRDM_AUTH_API_URL,
            useFactory: (config: ConfigService) => config.configuration?.userAuthApi.url,
            deps: [ConfigService]
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true
        },
        {
            provide: LG_APPLICATION_INSIGHTS_CONFIGURATION,
            useExisting: ApplicationInsightsConfigurationService
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgApplicationInsightsService,
            multi: true
        },
        /* {
            provide: ErrorHandler,
            useClass: LgErrorHandler
        },*/
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MetaInterceptorService,
            multi: true
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (state: SurveyAppState) => state.getNavigation$(),
            deps: [SurveyAppState]
        },
        {
            provide: LG_USER_INFO,
            useValue: {}
        },
        {
            provide: LG_USERFLOW_SERVICE,
            useExisting: LgUserflowService
        },
        {
            provide: LG_USERFLOW_CONFIGURATION,
            useClass: LgUserflowConfiguration
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: Auth0AuthenticationService
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
