export const enum VariableType {
    Integer = "integer",
    Decimal = "decimal",
    Date = "date",
    DateTime = "datetime",
    Varchar = "varchar",
    Encrypted = "encrypted",
    Paragraph = "paragraph",
    Dataset = "dataset",
    Reference = "reference",
    ExternalDataset = "external-dataset"
}
