import { Auth0Config } from "survey-authorization";

export interface AppConfiguration {
    environment: string;
    tenant: string;
    instance: string;
    auth0: Auth0Config;
    api: {
        url: string;
        validationUrl: string;
    };
    userAuthApi: {
        url: string;
    };
    matomo: {
        siteId: number;
        positionDimensionId: number;
    };
    applicationInsights: {
        instrumentationKey: string | null;
        correlationHeaderExcludedDomains: string[];
    };
    metadataOverrides?: {
        active?: boolean | null;
        selectable?: boolean | null;
        uiV1?: boolean | null;
        uiV2?: boolean | null;
    };
    userflow: {
        environmentKey: string;
        contentId: string;
    };
    dataEntryUrl: string;
    redirectRegistries: string[];
}
