import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateFn,
    CanMatchFn,
    UrlSegment,
    Route
} from "@angular/router";
import { of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { Auth0AuthorizationService } from "survey-authorization";

export const canActivateAuthorizationGuard: CanActivateFn = (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
) => {
    const auth = inject(Auth0AuthorizationService);
    return checkAccess(auth);
};

export const canMatchAuthorizationGuard: CanMatchFn = (_route: Route, _segments: UrlSegment[]) => {
    const auth = inject(Auth0AuthorizationService);
    return checkAccess(auth);
};

const checkAccess = (auth: Auth0AuthorizationService) => {
    return auth.isAuthenticated$.pipe(
        take(1),
        switchMap(loggedIn => {
            if (loggedIn) {
                return of(true);
            } else {
                return of(false);
            }
        })
    );
};
