import { Injectable } from "@angular/core";
import { AppConfiguration } from "./app-configuration";

@Injectable({ providedIn: "root" })
export class ConfigService {
    configuration: AppConfiguration | undefined;
    configurationPromise: Promise<AppConfiguration>;
    private _resolve!: null | ((value: AppConfiguration) => void);

    constructor() {
        this.configurationPromise = new Promise<AppConfiguration>(resolve => {
            this._resolve = resolve;
        });
    }

    getDoNotTrack(): boolean {
        return window.location.hostname === "localhost";
    }

    setConfigation(val: AppConfiguration): void {
        if (this._resolve === null) throw new Error("Configuration was already set");
        this.configuration = val;
        this._resolve(val);
        this._resolve = null;
    }
}
