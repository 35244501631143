import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    Attribute,
    OnDestroy,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";

import { takeUntil } from "rxjs/operators";

@Component({
    selector: "survey-paging",
    templateUrl: "./survey-paging.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "survey-paging"
    }
})
export class SurveyPagingComponent implements OnDestroy {
    @Input() totalPages = 0;
    _page = 1;

    private readonly _parameterName: string = "page";
    private readonly _destroyed$ = new Subject<void>();

    constructor(
        private _route: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        // eslint-disable-next-line @angular-eslint/no-attribute-decorator
        @Attribute("parameterName") parameterName: string
    ) {
        if (parameterName) this._parameterName = parameterName;

        this._route.queryParamMap.pipe(takeUntil(this._destroyed$)).subscribe(params => {
            const parameterValue = params.get(this._parameterName);
            let page = parameterValue ? +parameterValue : null;
            if (page === null || isNaN(page) || page < 1) page = 1;
            this._page = page;
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
