import _ from "lodash";
import { GetValidationReportsResponseEntry } from "./get-validation-reports-response";

export interface GetValidationReportDetailVariable {
    label: string;
    errors?: string[];
    warnings?: string[];
}

export interface GetValidationReportDetailSection {
    label: string;
    url: string;
    variables: _.Dictionary<GetValidationReportDetailVariable>;
}

export interface GetValidationReportDetailSubject {
    label: string;
    organisation_uri: string;
    sections: _.Dictionary<GetValidationReportDetailSection>;
}

export interface GetValidationReportDetailDataset {
    label: string;
    subjects: _.Dictionary<GetValidationReportDetailSubject>;
}

export interface GetValidationReportDetailData extends GetValidationReportsResponseEntry {
    subject_uri: string;
    project_uri: string;
    organisation_uri: string;
    organisations: string[];
    subject_count: number;
    datasets: _.Dictionary<GetValidationReportDetailDataset>;
}

export interface GetValidationReportDetailsResponse {
    data: GetValidationReportDetailData | [];
    survey_base_url: string;
    project: string;
    subject: string;
}
