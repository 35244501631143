import { SubjectsValidationReportEntry } from "./subjects-validation-report";

export interface SubjectValidationReportDetailsVariable {
    label: string;
    name: string;
    errors: string[] | null;
    warnings: string[] | null;
}

export interface SubjectValidationReportDetailsSection {
    label: string;
    name: string;
    viewUrl: string;
    variables: SubjectValidationReportDetailsVariable[];
}

export interface SubjectValidationReportDetailsSubject {
    label: string;
    uri: string;
    organizationUri: string;
    sections: SubjectValidationReportDetailsSection[];
}

export interface SubjectValidationReportDetailsDataset {
    label: string;
    name: string;
    subjects: SubjectValidationReportDetailsSubject[];
}

export interface SubjectValidationReportDetails extends SubjectsValidationReportEntry {
    projectUri: string;
    organizationUri: string;
    organizations: string[];
    subjectCount: number;
    datasets: SubjectValidationReportDetailsDataset[];
}
