import { XmlBoolean, XmlDescriptionType, XmlNumber, XmlStringElement } from "../responses";

export function convertDescriptionType(
    source: XmlDescriptionType[] | null | undefined,
    language: string,
    defaultLanguage: string,
    defaultText: string
): string;
export function convertDescriptionType(
    source: XmlDescriptionType[] | null | undefined,
    language: string,
    defaultLanguage: string,
    defaultText: string | null
): string | null;
export function convertDescriptionType(
    source: XmlDescriptionType[] | null | undefined,
    language: string,
    defaultLanguage: string,
    defaultText: string | null
): string | null {
    if (source == null || source.length === 0) return defaultText;
    // handle empty node which php serializes as [[]]
    if (source.length === 1 && Array.isArray(source[0])) return defaultText;
    if (source.length === 1) return source[0].content;
    // find the language string, following this order of precedence
    // 1) matching language attribute
    // 2) fallback language
    let fallback: string | null = null;
    for (const item of source) {
        const itemLanguage = item.language ?? defaultLanguage;
        if (itemLanguage === language) return item.content;
        if (itemLanguage === "nl") {
            fallback = item.content;
        }
    }
    return fallback ?? defaultText;
}

export function convertStringType(
    source: XmlStringElement[] | null | undefined,
    defaultText: string
): string;
export function convertStringType(
    source: XmlStringElement[] | null | undefined,
    defaultText: string | null
): string | null;
export function convertStringType(
    source: XmlStringElement[] | null | undefined,
    defaultText: string | null
): string | null {
    if (source == null || source.length === 0) return defaultText;
    // handle empty node which php serializes as [[]]
    if (source.length === 1 && Array.isArray(source[0])) return defaultText;
    return source[0].content;
}

export function convertBooleanString(
    source: XmlBoolean | null | undefined,
    defaultValue: boolean
): boolean {
    if (source == null) return defaultValue;
    return source === "true";
}

export function convertNumberString(
    source: XmlNumber | null | undefined,
    defaultValue: number
): number;
export function convertNumberString(
    source: XmlNumber | null | undefined,
    defaultValue: number | null
): number | null;
export function convertNumberString(
    source: XmlNumber | null | undefined,
    defaultValue: number | null
): number | null {
    if (source == null) return defaultValue;
    return +source;
}
