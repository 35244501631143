import _ from "lodash";

export interface ExternalOptionsetDefinition {
    code: string;
    dataset: string;
    dstSetVariablesDataset: string | null;
    srcSetVariablesDataset: string | null;
    relativePosition: string | null;
    listVariables: string[];
    setVariables: _.Dictionary<string>;
    listSubjectFilter: _.Dictionary<string>;
    sortVariables: string[];
    autoSearch: boolean;
}
