export type FindSubjectByKeyItem<T> = T & {
    subject_uri: string;
    organisation_uri: string;
    dataset_uri: string;
};

export type FindSubjectByKeyResponse<T> = {
    total: number;
    subjects: Array<FindSubjectByKeyItem<T>>;
};
