import { LayoutButtonDefinition } from "./layout-button-definition";
import { AutoincrementType } from "./autoincrement-type";
import { ControlType } from "./control-type";
import { ControlVariant } from "./control-variant";
import { OptionsetDefinition } from "./optionset-definition";
import { SearchScope } from "./search-scope";
import { VariableType } from "./variable-type";
import { VariableVisibility } from "./variable-visibility";
import { VariableLocalizations } from "./variable-localizations";
import { ResourceIcon } from "./resource-icon";

export interface VariableDefinition {
    type: VariableType;
    control: ControlType | null;
    controlVariant: ControlVariant | null;
    // structure: string | null; //drop?
    name: string;
    length: number | null;
    // promiseName: string | null; // drop?
    // promiseTag: string | null; // drop?
    // promiseOptionsetId: string | null; // drop?
    // externalName: string | null; //drop?
    required: boolean;
    matchFromStart: boolean;
    inNavigation: boolean;
    search: boolean;
    searchScope: SearchScope | null; // default organisation
    format: string | null;
    reformatTo: string | null;
    mask: string | null;
    autoIncrement: boolean;
    autoIncrementStart: number | null;
    autoIncrementType: AutoincrementType | null;
    category: string | null;
    categoryLabel: string | null;
    cardinality: number | null;
    isKey: boolean;
    isBarcodeScannerInput: boolean;
    isUnskippable: boolean;
    resourceIcon: ResourceIcon | null;
    visibility: VariableVisibility | null;
    forceOptionsetValue: boolean; // default true
    interesting: boolean; // default false
    excludeFromCopy: boolean; // default false
    dbName: string;
    label: string;
    description: string | null;
    help: string | null;
    // default?: [XmlDefaultType];
    // condition?: [XmlConditionType];
    // calculation?: [XmlCalculationDefinition];
    // validation?: XmlValidationDefinition[];
    // "data-validation"?: XmlDataValidationDefinition[];
    optionset: OptionsetDefinition | null;
    // plugin?: [XmlPluginDefinition];
    // maxCardinality?: [XmlCardinalityDefinition];
    // minCardinality?: [XmlCardinalityDefinition];
    dataset: string | null;
    project: string | null;
    layoutButton?: LayoutButtonDefinition; // never sent by backend
    localizations: VariableLocalizations;
}
