import { HttpContextToken, HttpInterceptor } from "@angular/common/http";
import { InjectionToken } from "@angular/core";

export interface InterceptorChainDefinition {
    [id: string]: Array<new () => HttpInterceptor>;
}

export const INTERCEPTOR_CHAIN_DEFINITION = new InjectionToken<InterceptorChainDefinition>(
    "INTERCEPTOR_CHAIN_DEFINITION"
);

export const META_INTERCEPTOR_CHAIN = new HttpContextToken<string | null>(() => null);
