import { OptionImageDefinition } from "./option-image-definition";

export interface OptionDefinition {
    value: string;
    externalValue: string | null;
    label: string;
    description: string | null;
    image: OptionImageDefinition | null;
    // condition?: [XmlConditionType];
}
