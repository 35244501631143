import type { DatasetKeyDefinition } from "./dataset-key-definition";
import type { DatasetLocalizations } from "./dataset-localizations";
import type { SectionDefinition } from "./section-definition";
import type { SortDefinition } from "./sort-definition";
import type { LayoutDefinition } from "./layout-definition";

export interface DatasetDefinition {
    name: string;
    // "promise-name"?: string;
    dbName: string;
    layout: LayoutDefinition;
    // reference?: [XmlStringElement];
    label: string;
    description: string | null;
    // lock?: [XmlLockType];
    keys: DatasetKeyDefinition[];
    sort: SortDefinition[] | null;
    parentDataset: string | null;
    sections: SectionDefinition[];
    defaultEditSection: string | null;
    defaultCreateSection: string | null;
    indelible: boolean;
    layoutHideAddSubjectInSections: string[]; // redundant, should be removed when Jan Kees modifies the project xml
    localizations: DatasetLocalizations;
}
