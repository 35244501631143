import * as _ from "lodash";

export interface SurveyResource {
    uri: string;
    label: string;
}

export interface SurveyProjectProperties {
    parent_uri: string | null;
    product: string;
    active: "1" | "0";
    selectable: "1" | "0";
    ui_v1_enabled: "1" | "0";
    ui_v2_enabled: "1" | "0";
    has_year_popup: 1 | 0;
}
export interface SurveyProjectResource extends SurveyResource {
    properties: SurveyProjectProperties;
}

export interface ProjectSelectionResponse {
    projects: _.Dictionary<SurveyProjectResource[]>;
    organisations: SurveyResource[];
    selected: {
        project: string;
        organisation: string;
    };
}
