import _ from "lodash";

export interface GetValidationReportsResponseEntry {
    error_count: string;
    warning_count: string;
    updated: string;
    validated: string;
    subject_label: string;
}

export interface GetValidationReportsResponse {
    data: {
        subjects: _.Dictionary<GetValidationReportsResponseEntry>;
        start: number;
        count: number;
        total: number;
    };
    pageNumber: number | string;
    totalPages: number | string;
    itemsPerPage: number | string;
    registeredYear: number | string;
    years: number[];
}
