export interface AvailableValidationReport {
    description: string;
    report: string;
    title: string;
    uri: string;
    manual?: {
        description: string;
        manual: string;
    };
}
