import _ from "lodash";

export interface GenericValidationReport {
    data: Array<_.Dictionary<string>>;
    meta: {
        total: number;
        limit: number;
        page: number;
        offset: number;
    };
    labels: _.Dictionary<string>;
}
