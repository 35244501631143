export interface ProjectResponse {
    project: [XmlProjectDefinition];
}

export type GetProjectResponse = { error: string } | ProjectResponse;

export type XmlBoolean = "true" | "false";

export type XmlNumber = string;

export interface XmlStringElement {
    content: string;
}

export interface XmlDescriptionType extends XmlStringElement {
    language: string;
}

export interface XmlColorType extends XmlStringElement {
    name: string;
}

export interface XmlLockType extends XmlStringElement {
    label?: string;
}

export interface XmlConditionType extends XmlStringElement {
    label?: string;
}

export const enum XmlOptionVisibilityEnum {
    Disabled = "disabled",
    Hidden = "hidden"
}

export const enum XmlVisibilityEnum {
    Visible = "visible",
    Hidden = "hidden",
    Readonly = "readonly"
}

export interface XmlLayoutButtonInitialValueItemType {
    set: [XmlStringElement];
    source: [XmlStringElement];
}

export interface XmlLayoutButtonInitialValueType {
    item: XmlLayoutButtonInitialValueItemType[];
}

export interface XmlLayoutButtonType {
    action: "create";
    after: string;
    dataset: string;
    project: string;
    label: [XmlStringElement];
    "initial-values"?: [XmlLayoutButtonInitialValueType];
}

export interface XmlLayoutType {
    columns?: XmlNumber;
    previoussectionbutton?: XmlBoolean;
    nextsectionbutton?: XmlBoolean;
    "option-visibility"?: XmlOptionVisibilityEnum; // default disabled
    addsubjectbutton?: XmlBoolean;
    addcopyofsubjectbutton?: XmlBoolean;
    button?: XmlLayoutButtonType[];
}

export interface XmlMenuType {
    fixed: XmlBoolean;
    multilevel: XmlNumber;
}

export const enum XmlControlEnum {
    Radio = "radio",
    Select = "select",
    Autocomplete = "autocomplete",
    Textarea = "textarea"
}

export const enum XmlControlTypeEnum {
    Vertical = "vertical",
    Horizontal = "horizontal",
    Count = "count"
}

export interface XmlProjectControlType {
    default: XmlControlEnum;
}

export interface XmlStatementType {
    show: XmlBoolean;
    statement: [XmlDescriptionType];
    "error-message": [XmlDescriptionType];
}

export interface XmlProjectDefinition {
    name: string;
    "db-name": string;
    multicenter?: XmlBoolean; // default false
    "default-language"?: string;
    label?: XmlDescriptionType[];
    description: XmlDescriptionType[];
    logo?: [XmlStringElement];
    organisation?: [XmlStringElement];
    email?: [XmlStringElement];
    color?: XmlColorType[];
    layout?: [XmlLayoutType];
    filter?: [XmlVariableType];
    menu?: [XmlMenuType];
    controls?: [XmlProjectControlType];
    "statement-to-agree"?: [XmlStatementType];
    dataset: XmlDatasetType[];
}

export interface XmlDatasetKeyDefinition {
    name: string;
    description?: [XmlDescriptionType]; // TODO: mess when empty
    variable: XmlKeyVariableType[];
}

export interface XmlKeyVariableType {
    name: string;
    "db-name": string;
    default?: [XmlDefaultType];
}

export interface XmlDatasetKeysType {
    key: XmlDatasetKeyDefinition[];
}

export interface XmlSectionDefinition {
    name: string;
    plugin?: string;
    "promise-tag"?: string;
    layout?: [XmlLayoutType];
    label: XmlDescriptionType[];
    description?: XmlDescriptionType[];
    condition?: [XmlConditionType];
    lock?: [XmlLockType];
    variable: XmlVariableType[];
    "generate-link"?: string;
    "session-link"?: string;
}

export const enum XmlVariableEnum {
    Integer = "integer",
    Decimal = "decimal",
    Date = "date",
    DateTime = "datetime",
    Varchar = "varchar",
    Encrypted = "encrypted",
    Paragraph = "paragraph",
    Dataset = "dataset",
    Reference = "reference",
    ExternalDataset = "external-dataset"
}

export const enum XmlSearchScopeEnum {
    Organisation = "organisation",
    Project = "project",
    Subject = "subject"
}

export const enum XmlAutoincrementEnum {
    Organisation = "organisation",
    Project = "project"
}

export interface XmlVariableType {
    type: XmlVariableEnum;
    control?: XmlControlEnum;
    "control-type"?: XmlControlTypeEnum;
    structure?: string;
    name: string;
    length?: XmlNumber;
    "promise-name"?: string;
    "promise-tag"?: string;
    "promise-optionset-id"?: string;
    "external-name"?: string;
    required?: XmlBoolean;
    "match-from-start"?: XmlBoolean;
    "in-navigation"?: XmlBoolean;
    search?: XmlBoolean;
    "search-scope"?: XmlSearchScopeEnum; // default organisation
    format?: string;
    "reformat-to"?: string;
    mask?: string;
    "auto-increment"?: XmlBoolean;
    "auto-increment-start"?: XmlNumber;
    "auto-increment-type"?: XmlAutoincrementEnum;
    category?: string;
    "category-label"?: string;
    cardinality?: XmlNumber;
    "is-key"?: XmlBoolean;
    visibility?: XmlVisibilityEnum;
    "force-optionset-value"?: XmlBoolean; // default true
    interesting?: XmlBoolean; // default false
    excludefromcopy?: XmlBoolean; // default false
    "db-name": string;
    label: XmlDescriptionType[];
    description?: XmlDescriptionType[];
    help?: XmlDescriptionType[];
    default?: [XmlDefaultType];
    condition?: [XmlConditionType];
    calculation?: [XmlCalculationDefinition];
    validation?: XmlValidationDefinition[];
    "data-validation"?: XmlDataValidationDefinition[];
    optionset?: [XmlOptionSetDefinition];
    plugin?: [XmlPluginDefinition];
    maxCardinality?: [XmlCardinalityDefinition];
    minCardinality?: [XmlCardinalityDefinition];
    dataset?: string;
    project?: string;
}
export interface XmlPluginDefinition {
    name?: string;
    arguments?: string;
}

export interface XmlCardinalityDefinition {
    expression: [XmlStringElement];
}

export interface XmlCalculationDefinition {
    label?: string;
    expression?: XmlStringElement[];
}

export interface XmlOptionExternalType {
    code: [XmlStringElement];
    parameters?: [XmlParametersDefinition];
    action?: "autosearch";
}

export interface XmlParametersDefinition {
    parameter?: XmlParameterDefinition[];
}

export interface XmlParameterDefinition {
    key: [XmlStringElement];
    value: [XmlStringElement];
}

export interface XmlOptionSetDefinition {
    name: string;
    structure?: string;
    "option-visibility"?: XmlOptionVisibilityEnum; // default disabled
    search?: string;
    external?: [XmlOptionExternalType];
    option?: XmlOptionType[];
}

export interface XmlOptionType {
    value: string;
    "external-value"?: string;
    label: XmlDescriptionType[];
    description?: XmlDescriptionType[];
    condition?: [XmlConditionType];
}

export const enum XmlValidationEnum {
    Error = "error",
    Warning = "warning",
    Info = "info",
    Text = "text"
}

export interface XmlValidationDefinition {
    name?: string;
    type?: XmlValidationEnum; // default error
    plugin?: string;
    "plugin-arguments"?: string;
    label?: string;
    expression: [XmlStringElement];
    message?: XmlDescriptionType[];
}

export interface XmlDataValidationDefinition {
    expression: [XmlStringElement];
    message: [XmlStringElement];
    name?: string;
    type?: XmlValidationEnum; // default error
    label?: string;
}

export interface XmlDefaultType {
    expression: XmlStringElement[];
}

export interface XmlSortDefinition {
    variable: XmlSortVariableDefinition[];
}

export interface XmlSortVariableDefinition {
    name: string;
    direction?: "ascending" | "descending"; // default: ascending
    "db-name": string;
}

export interface XmlDatasetNavigationDefinition {
    create?: [XmlStringElement];
    default?: [XmlStringElement];
}

export interface XmlDatasetType {
    name: string;
    "promise-name"?: string;
    "db-name": string;
    "can-create"?: "1" | "0";
    layout?: [XmlLayoutType];
    reference?: [XmlStringElement];
    label: XmlDescriptionType[];
    description?: XmlDescriptionType[];
    lock?: [XmlLockType];
    keys?: [XmlDatasetKeysType];
    navigate?: [XmlDatasetNavigationDefinition];
    sort?: [XmlSortDefinition];
    section: XmlSectionDefinition[];
}
