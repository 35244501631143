import _ from "lodash";

export interface GetExternalDatasetResponseEntry {
    cols: _.Dictionary<string>;
    full: _.Dictionary<string>;
    section: string;
    total: number;
}

export type GetExternalDatasetResponse = [GetExternalDatasetResponseEntry | []];
