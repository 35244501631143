import _ from "lodash";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, retryWhen, switchMap } from "rxjs/operators";

import {
    AvailableValidationReport,
    GenericValidationReport,
    SubjectsValidationReport,
    SubjectValidationReportDetails
} from "../models";
import { convertSurveyError } from "./helpers/convert-survey-error";
import {
    GetAvailableReportsResponse,
    GetReportInformationResponse,
    GetValidationReportDetailsResponse,
    GetValidationReportsResponse
} from "./responses";
import { SurveyRestApiService } from "./survey-rest-api.service";

export const SURVEY_VALIDATION_REPORTS_API_URL = new InjectionToken<string>(
    "SurveyValidationReportsApiUrl"
);

// note: ideally we would respect the token content, but that would mean decoding it
const ASSUMED_TOKEN_VALIDITY = 59 * 1000;
@Injectable({
    providedIn: "root"
})
export class SurveyValidationReportsApiService {
    private _token: string | null = null;
    private _tokenProjectUri: string | null = null;
    private _tokenOrganizationUri: string | null = null;
    private _tokenAssumedExpiration: number | null = null;

    constructor(
        @Inject(SURVEY_VALIDATION_REPORTS_API_URL) private _baseUrl: string,
        private _httpClient: HttpClient,
        private _restApi: SurveyRestApiService
    ) {}

    getInformation(
        organizationUri: string,
        projectUri: string
    ): Observable<GetReportInformationResponse> {
        return this._doGet<GetReportInformationResponse>(organizationUri, projectUri, ``);
    }

    getAvailableReports(
        organizationUri: string,
        projectUri: string
    ): Observable<AvailableValidationReport[]> {
        return this._doGet<GetAvailableReportsResponse>(
            organizationUri,
            projectUri,
            `available`
        ).pipe(
            map(res => res.data)
            // map(available =>
            //     isDevMode()
            //         ? [
            //               ...available,
            //               {
            //                   uri: "signaleringslijst_fup1jr",
            //                   title: "Signaleringslijst Follow-up 1 jaar",
            //                   description:
            //                       "Beschrijving voor de Signaleringslijst follow-up 1 jaar rapportage komt hier",
            //                   report: "?"
            //               }
            //           ]
            //         : available
            // )
        );
    }

    getGenericReport(
        organizationUri: string,
        projectUri: string,
        viewUri: string,
        sortField?: string,
        sortOrder?: "asc" | "desc"
    ): Observable<GenericValidationReport> {
        const params: _.Dictionary<string> = {};
        if (sortField) {
            params["sortField"] = sortField;
            if (!sortOrder) sortOrder = "asc";
        }
        if (sortOrder) {
            params["sortOrder"] = sortOrder;
        }

        return this._doGet<GenericValidationReport>(
            organizationUri,
            projectUri,
            `reports/${viewUri}`,
            params
        );
    }

    getValidationReport(
        organizationUri: string,
        projectUri: string,
        registrationYear?: string | null,
        pageNumber?: number | null,
        pageSize?: number | null
    ): Observable<SubjectsValidationReport> {
        const params: _.Dictionary<string> = {};
        if (registrationYear) params["registeredYear"] = registrationYear;
        if (pageNumber) params["page"] = pageNumber.toString();
        if (pageSize) params["itemsPerPage"] = pageSize.toString();

        return this._doGet<GetValidationReportsResponse>(
            organizationUri,
            projectUri,
            `reports/validations`,
            params
        ).pipe(
            map(response => ({
                pageNumber: +response.pageNumber,
                totalPages: +response.totalPages,
                itemsPerPage: +response.itemsPerPage,
                registeredYear: response.registeredYear.toString(),
                years: response.years,
                subjects: Object.keys(response.data.subjects ?? {}).map(uri => {
                    const src = response.data.subjects[uri];
                    return {
                        errorCount: +src.error_count,
                        warningCount: +src.warning_count,
                        updated: src.updated ? new Date(src.updated) : null,
                        validated: src.validated ? new Date(src.validated) : null,
                        label: src.subject_label,
                        uri
                    };
                })
            }))
        );
    }

    getValidationReportDetails(
        organizationUri: string,
        projectUri: string,
        subjectUri: string
    ): Observable<SubjectValidationReportDetails | null> {
        return this._doGet<GetValidationReportDetailsResponse>(
            organizationUri,
            projectUri,
            `reports/validations/${subjectUri}`
        ).pipe(map(response => this._convertValidationDetails(response)));
    }

    private _convertValidationDetails(
        src: GetValidationReportDetailsResponse
    ): SubjectValidationReportDetails | null {
        if (!src.data || _.isArray(src.data)) return null;
        const data = src.data;
        return {
            errorCount: +data.error_count,
            warningCount: +data.warning_count,
            updated: data.updated ? new Date(data.updated) : null,
            validated: data.validated ? new Date(data.validated) : null,
            label: data.subject_label,
            uri: data.subject_uri,
            organizationUri: data.organisation_uri,
            projectUri: data.project_uri,
            subjectCount: +data.subject_count,
            organizations: data.organisations,
            datasets: Object.keys(data.datasets).map(datasetName => {
                const dataset = data.datasets[datasetName];
                return {
                    label: dataset.label,
                    name: datasetName,
                    subjects: Object.keys(dataset.subjects).map(subjectUri => {
                        const subject = dataset.subjects[subjectUri];
                        return {
                            label: subject.label,
                            organizationUri: subject.organisation_uri,
                            uri: subjectUri,
                            sections: Object.keys(subject.sections).map(sectionName => {
                                const section = subject.sections[sectionName];
                                return {
                                    label: section.label,
                                    name: sectionName,
                                    viewUrl: section.url,
                                    variables: Object.keys(section.variables).map(variableName => {
                                        const variable = section.variables[variableName];
                                        return {
                                            label: variable.label,
                                            name: variableName,
                                            errors: variable.errors ?? null,
                                            warnings: variable.warnings ?? null
                                        };
                                    })
                                };
                            })
                        };
                    })
                };
            })
        };
    }

    private _getToken(organizationUri: string, projectUri: string): Observable<string | null> {
        if (
            this._token &&
            organizationUri === this._tokenOrganizationUri &&
            projectUri === this._tokenProjectUri &&
            Date.now() < this._tokenAssumedExpiration!
        ) {
            return of(this._token);
        }

        this._token = null;
        return this._restApi.getValidationReportToken(organizationUri, projectUri).pipe(
            map(token => {
                if (this._token === null) {
                    this._token = token;
                    this._tokenOrganizationUri = organizationUri;
                    this._tokenProjectUri = projectUri;
                    this._tokenAssumedExpiration = Date.now() + ASSUMED_TOKEN_VALIDITY;
                }
                return token;
            }),
            catchError(error => {
                console.error(error);
                return of(null);
            })
        );
    }

    private _doGet<TResponse>(
        organizationUrl: string,
        projectUrl: string,
        url: string,
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              }
    ): Observable<TResponse> {
        return this._getToken(organizationUrl, projectUrl)
            .pipe(map(token => [this._baseUrl, token]))
            .pipe(
                switchMap(([baseUrl, token]) =>
                    this._httpClient.get<TResponse>(`${baseUrl}/${url}`, {
                        params,
                        headers: {
                            token: token ?? ""
                        }
                    })
                ),
                retryWhen(error =>
                    error.pipe(
                        mergeMap((response, i) => {
                            // on first expired token failure, try to get a new token
                            if (
                                i === 0 &&
                                response.status === 500 &&
                                response.error?.message === "Expired token"
                            ) {
                                this._token = null;
                                return of(null);
                            } else {
                                throw response;
                            }
                        })
                    )
                ),
                catchError(error => throwError(convertSurveyError(error)))
            );
    }
}
