import _ from "lodash";

export interface LayoutButtonDefinition {
    action: "create";
    label: string;
    after: string | null;
    dataset: string;
    project: string | null;
    initialValues: _.Dictionary<string> | null;
}
