export interface SearchSubjectsResponseEntry {
    subject_uri: string;
    dataset_uri: string;
    variable_uri: string;
    organisation_uri: string;
    label: string;
    href: string;
}

export interface SearchSubjectsResponse {
    organisation: SearchSubjectsResponseEntry[];
    total: number;
}
