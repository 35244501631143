export interface CreateSubjectResponseImpl {
    subjectUri: string;
    datasetUri: string;
    section: string;
    isNew: boolean;
}

export interface CreateSubjectResponse {
    subjectUris: string[];
    datasetUris: string[];
    section: string;
    isNew: boolean;
}
