import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { SurveyPagingComponent, SurveyPageSizeComponent } from "./components/index";

const publicComponents = [SurveyPageSizeComponent, SurveyPagingComponent];

@NgModule({
    imports: [CommonModule, RouterModule, UiCoreModule, LgLocalizationModule],
    exports: [...publicComponents],
    declarations: [...publicComponents],
    providers: []
})
export class SharedModule {}
