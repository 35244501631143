import { Component, OnInit, OnDestroy } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { Subject } from "rxjs";

import { Auth0AuthorizationService } from "survey-authorization";
import { SurveyAppState } from "@shared";
import { takeUntil } from "rxjs/operators";
import { SurveyRestApiService } from "survey-api";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    _errorMessage: string | null = null;
    private readonly _destroyed$ = new Subject<void>();

    constructor(
        private _auth0Authorization: Auth0AuthorizationService,
        private _stateService: SurveyAppState,
        private _surveyApi: SurveyRestApiService
    ) {}

    ngOnInit(): void {
        registerLocaleData(localeNl);

        this._auth0Authorization.authorizationError$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(error => {
                this._errorMessage = error.message;
                console.log(error);
                setTimeout(() => this._logout(), 30000);
            });

        this._auth0Authorization.newProfile$.subscribe(() => window.location.reload());
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _logout($event?: Event): void {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        this._auth0Authorization.logout();
    }
}
