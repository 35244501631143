import * as _ from "lodash";

import {
    DatasetDefinition,
    DatasetKeyDefinition,
    ExternalOptionsetDefinition,
    LayoutButtonDefinition,
    LayoutDefinition,
    OptionDefinition,
    OptionsetDefinition,
    OptionVisibility,
    ProjectDefinition,
    SectionDefinition,
    SortDefinition,
    StatementDefinition,
    VariableDefinition,
    VariableType
} from "../../models";
import {
    XmlColorType,
    XmlDatasetKeyDefinition,
    XmlDatasetType,
    XmlLayoutButtonType,
    XmlLayoutType,
    XmlOptionSetDefinition,
    XmlOptionExternalType,
    XmlOptionType,
    XmlProjectDefinition,
    XmlSectionDefinition,
    XmlSortVariableDefinition,
    XmlStatementType,
    XmlVariableType
} from "../responses/get-project-response";
import {
    convertBooleanString,
    convertDescriptionType,
    convertNumberString,
    convertStringType
} from "./convert-project-definition-core";
import {
    convertAutoIncrementType,
    convertControlType,
    convertControlVariant,
    convertOptionVisibility,
    convertSearchScope,
    convertVariableType,
    convertVariableVisibility
} from "./convert-project-definition-enums";

function convertLayoutButtons(buttons: XmlLayoutButtonType[]): LayoutButtonDefinition[] {
    return buttons.map(button => {
        let initialValues: _.Dictionary<string> | null = null;
        if (button["initial-values"]?.length) {
            initialValues = {};
            button["initial-values"][0].item.forEach(item => {
                const set = convertStringType(item.set, "");
                const source = convertStringType(item.source, "");
                if (set === "" || source === "") return;
                initialValues![set] = source;
            });
        }
        return {
            action: button.action,
            after: button.after === "" ? null : button.after,
            dataset: button.dataset,
            initialValues,
            label: convertStringType(button.label, ""),
            project: button.project === "" ? null : button.project
        };
    });
}

function convertLayout(
    source: [XmlLayoutType] | null | undefined,
    parent: LayoutDefinition | null
): LayoutDefinition {
    return {
        addCopy: convertBooleanString(
            source?.[0]?.addcopyofsubjectbutton,
            parent?.addCopy ?? false
        ),
        addSubject: convertBooleanString(
            source?.[0]?.addsubjectbutton,
            parent?.addSubject ?? false
        ),
        previousSection: convertBooleanString(
            source?.[0]?.previoussectionbutton,
            parent?.previousSection ?? source?.[0]?.nextsectionbutton === "true"
        ),
        nextSection: convertBooleanString(
            source?.[0]?.nextsectionbutton,
            parent?.nextSection ?? false
        ),
        optionVisibility:
            convertOptionVisibility(source?.[0]?.["option-visibility"]) ??
            parent?.optionVisibility ??
            OptionVisibility.Disabled,
        columns: convertNumberString(source?.[0]?.columns, parent?.columns ?? 1),
        // buttons are not inherited
        buttons: source?.[0].button?.length ? convertLayoutButtons(source[0].button) : null
    };
}

function convertColors(source: XmlColorType[] | null | undefined): _.Dictionary<string> {
    const result: _.Dictionary<string> = {};
    if (source) {
        for (const color of source) {
            result[color.name] = color.content;
        }
    }

    return result;
}

function convertStatement(
    source: XmlStatementType[] | null | undefined,
    language: string,
    defaultLanguage: string
): StatementDefinition | null {
    if (source == null || source.length === 0) return null;
    return {
        showMessage: convertBooleanString(source[0].show, false),
        messageText: convertDescriptionType(source[0].statement, language, defaultLanguage, ""),
        messageError: convertDescriptionType(
            source[0]["error-message"],
            language,
            defaultLanguage,
            ""
        )
    };
}

function convertKeyDefinition(
    source: XmlDatasetKeyDefinition,
    language: string,
    defaultLanguage: string
): DatasetKeyDefinition {
    return {
        name: source.name,
        description: convertDescriptionType(source.description, language, defaultLanguage, null),
        variables: _.map(source.variable, v => ({
            name: v.name,
            dbName: v["db-name"],
            default: convertStringType(v.default?.[0]?.expression, null)
        }))
    };
}

function convertSortVariable(source: XmlSortVariableDefinition): SortDefinition {
    return {
        name: source.name,
        dbName: source["db-name"],
        direction: source.direction === "descending" ? "descending" : "ascending"
    };
}

function convertOption(
    source: XmlOptionType,
    language: string,
    defaultLanguage: string
): OptionDefinition {
    return {
        label: convertDescriptionType(source.label, language, defaultLanguage, ""),
        description: convertDescriptionType(source.description, language, defaultLanguage, null),
        externalValue: source["external-value"] ?? null,
        value: source.value,
        image: null
    };
}

function getExternalOptionSetParameter(source: XmlOptionExternalType, id: string): string | null {
    if (!source.parameters) return null;
    const parameter = source.parameters[0].parameter?.find(
        p => convertStringType(p.key, "") === id
    );
    return parameter ? convertStringType(parameter.value, null) : null;
}

function convertExternalOptionSet(source: XmlOptionExternalType): ExternalOptionsetDefinition {
    const datasetParam = getExternalOptionSetParameter(source, "dataset");
    const relativePositionParam = getExternalOptionSetParameter(source, "relative_position");
    const sortVariablesParam = getExternalOptionSetParameter(source, "sort_variables");
    const listVariablesParam = getExternalOptionSetParameter(source, "listVariables");
    const setVariablesParam = getExternalOptionSetParameter(source, "setVariables");
    const listSubjectFilterParam = getExternalOptionSetParameter(source, "listSubjectFilter");
    const setVariables: _.Dictionary<string> = {};
    if (setVariablesParam) {
        setVariablesParam.split(",").forEach(part => {
            const eqPos = part.indexOf("=");
            if (eqPos === -1) return;
            const key = part.substring(0, eqPos).trim();
            const val = part.substring(eqPos + 1).trim();
            if (key === "" || val === "") return;
            setVariables[key] = val;
        });
    }

    const listSubjectFilter: _.Dictionary<string> = {};
    if (listSubjectFilterParam) {
        listSubjectFilterParam.split(",").forEach(part => {
            part = part.trim();
            if (part === "") return;
            const colonPos = part.indexOf(":");
            if (colonPos === -1) {
                listSubjectFilter[part] = part;
            } else {
                const key = part.substring(0, colonPos).trim();
                const val = part.substring(colonPos + 1).trim();
                if (key === "" || val === "") return;
                listSubjectFilter[key] = val;
            }
        });
    }

    const sortVariables = (sortVariablesParam?.split(",") ?? []).map(val => {
        if (val.endsWith(" desc")) return "-" + val.substring(0, val.length - 5);
        if (val.endsWith(" asc")) return val.substring(0, val.length - 4);
        return val;
    });

    return {
        code: convertStringType(source.code, ""),
        dataset: datasetParam ?? "",
        relativePosition: relativePositionParam,
        listVariables: listVariablesParam ? listVariablesParam.split(",") : [],
        setVariables,
        sortVariables,
        listSubjectFilter,
        dstSetVariablesDataset: getExternalOptionSetParameter(source, "dstSetVariablesDataset"),
        srcSetVariablesDataset: getExternalOptionSetParameter(source, "srcSetVariablesDataset"),
        autoSearch: source.action === "autosearch"
    };
}

function convertOptionSet(
    source: XmlOptionSetDefinition[] | null | undefined,
    language: string,
    defaultLanguage: string
): OptionsetDefinition | null {
    if (source == null || source.length === 0) return null;
    const src = source[0];
    const external = !!src.external;
    return {
        name: src.name,
        optionVisibility: convertOptionVisibility(source[0]["option-visibility"]),
        searchLabel: src.search ?? null,
        external,
        options: external
            ? null
            : _.map(src.option, o => convertOption(o, language, defaultLanguage)),
        externalDefinition: external ? convertExternalOptionSet(src.external![0]) : null
    };
}

function convertVariable(
    source: XmlVariableType,
    language: string,
    defaultLanguage: string
): VariableDefinition {
    const autoIncrement = convertBooleanString(source["auto-increment"], false);
    const search = convertBooleanString(source.search, false);

    return {
        type: convertVariableType(source.type),
        control: convertControlType(source.control),
        controlVariant: convertControlVariant(source["control-type"]),
        name: source.name,
        length: convertNumberString(source.length, null),
        required: convertBooleanString(source.required, false),
        matchFromStart: convertBooleanString(source["match-from-start"], false),
        inNavigation: convertBooleanString(source["in-navigation"], true),
        search,
        searchScope: search ? convertSearchScope(source["search-scope"]) : null,
        format: source.format ?? null,
        reformatTo: source["reformat-to"] ?? null,
        mask: source.mask ?? null,
        autoIncrement,
        autoIncrementStart: autoIncrement
            ? convertNumberString(source["auto-increment-start"], 1)
            : null,
        autoIncrementType: autoIncrement
            ? convertAutoIncrementType(source["auto-increment-type"])
            : null,
        category: source.category ?? null,
        categoryLabel: source["category-label"] ?? null,
        cardinality: convertNumberString(source.cardinality, null),
        isKey: convertBooleanString(source["is-key"], false),
        isBarcodeScannerInput: false,
        isUnskippable: false,
        visibility: convertVariableVisibility(source.visibility),
        forceOptionsetValue: convertBooleanString(source["force-optionset-value"], true),
        interesting: convertBooleanString(source.interesting, false),
        excludeFromCopy: convertBooleanString(source.excludefromcopy, false),
        dbName: source["db-name"],
        label: convertDescriptionType(source.label, language, defaultLanguage, ""),
        description: convertDescriptionType(source.description, language, defaultLanguage, null),
        help: convertDescriptionType(source.help, language, defaultLanguage, null),
        resourceIcon: null,
        optionset: convertOptionSet(source.optionset, language, defaultLanguage),
        dataset: source.dataset ?? null,
        project: source.project ?? null,
        localizations: {}
    };
}

function convertSection(
    source: XmlSectionDefinition,
    language: string,
    defaultLanguage: string,
    parentLayout: LayoutDefinition
): SectionDefinition {
    return <SectionDefinition>{
        name: source.name,
        label: convertDescriptionType(source.label, language, defaultLanguage, ""),
        description: convertDescriptionType(source.description, language, defaultLanguage, null),
        layout: convertLayout(source.layout, parentLayout),
        generateLink: source["generate-link"] ?? null,
        sessionLink: source["session-link"] ?? null,
        variables: _.map(source.variable, v => convertVariable(v, language, defaultLanguage))
    };
}

function convertDataset(
    source: XmlDatasetType,
    language: string,
    defaultLanguage: string,
    parentLayout: LayoutDefinition | null
): DatasetDefinition {
    if ((source.keys?.length ?? 0) > 1) throw new Error("Unexpected number of keys");
    if ((source.sort?.length ?? 0) > 1) throw new Error("Unexpected number of sorts");
    const layout = convertLayout(source.layout, parentLayout);
    return {
        name: source.name,
        dbName: source["db-name"],
        layout,
        layoutHideAddSubjectInSections: [],
        label: convertDescriptionType(source.label, language, defaultLanguage, ""),
        description: convertDescriptionType(source.description, language, defaultLanguage, null),
        keys: _.map(source.keys?.[0]?.key, k => convertKeyDefinition(k, language, defaultLanguage)),
        sort: source.sort?.length
            ? _.map(source.sort?.[0].variable, s => convertSortVariable(s))
            : null,
        sections: _.map(source.section, s => convertSection(s, language, defaultLanguage, layout)),
        parentDataset: null,
        defaultCreateSection: convertStringType(source.navigate?.[0]?.create, null),
        defaultEditSection: convertStringType(source.navigate?.[0]?.default, null),
        indelible: false,
        localizations: {}
    };
}

function connectDatasets(project: ProjectDefinition): void {
    const parents: _.Dictionary<string> = {};

    project.datasets.forEach(dataset => {
        dataset.sections.forEach(section => {
            section.variables.forEach(variable => {
                if (variable.type === VariableType.Dataset) parents[variable.name] = dataset.name;
            });
        });
    });

    let root: string | null = null;

    project.datasets.forEach(dataset => {
        const parent = parents[dataset.name];
        if (parent) {
            dataset.parentDataset = parent;
        } else if (root === null) {
            root = dataset.name;
        } else {
            throw new Error(`Multiple root datasets: ${root}, ${dataset.name}`);
        }
    });

    project.rootDataset = root!;
}

export function convertProjectDefinition(
    source: XmlProjectDefinition,
    language: string
): ProjectDefinition {
    const defaultLanguage = source["default-language"] ?? "nl";
    const layout = convertLayout(source.layout, null);
    const datasets = _.map(source.dataset, d =>
        convertDataset(d, language, defaultLanguage, layout)
    );

    const result: ProjectDefinition = {
        name: source.name,
        dbName: source["db-name"],
        multicenter: convertBooleanString(source.multicenter, false),
        defaultLanguage,
        label: convertDescriptionType(source.label, language, defaultLanguage, ""),
        description: convertDescriptionType(source.description, language, defaultLanguage, null),
        logo: convertStringType(source.logo, null),
        organisation: convertStringType(source.organisation, null),
        email: convertStringType(source.email, null),
        colors: convertColors(source.color),
        layout,
        fixedMenu: convertBooleanString(source.menu?.[0]?.fixed, false),
        multilevelMenu: convertNumberString(source.menu?.[0]?.multilevel, 0),
        defaultControl: convertControlType(source.controls?.[0]?.default),
        filter:
            source.filter && source.filter.length
                ? convertVariable(source.filter[0], language, defaultLanguage)
                : null,
        statementToAgree: convertStatement(source["statement-to-agree"], language, defaultLanguage),
        datasets,
        localizations: {},
        rootDataset: "unknown",
        skipEmptyCreationDialog: false,
        hideSubjectCountInSubjectList: false,
        reports: {}
    };

    connectDatasets(result);

    return result;
}
