import { OrganizationMetadata } from "./organization-metadata";
import { ProjectMetadata } from "./project-metadata";

export interface ProjectSelection {
    projects: _.Dictionary<ProjectMetadata[]>;
    organizations: OrganizationMetadata[];
    selected: {
        projectUri: string;
        organizationUri: string;
    };
}
