import { XmlStringElement, XmlVariableEnum, XmlVisibilityEnum } from "./get-project-response";

export type XmlSubjectBoolean = "1" | "0";

export const enum XmlSubjectValidationMessageEnum {
    Text = "text",
    Error = "error",
    Exception = "exception",
    Warning = "warning",
    Hidden = "hidden"
}

export interface XmlSubject {
    name: string;
    user: string;
    created: string;
    updated: string;
    organisation: string;
    "can-delete": XmlSubjectBoolean;
    variable: XmlSubjectVariable[];
    dataset?: XmlSubjectDataset[];
    label: [XmlStringElement];
}

export interface XmlSubjectDataset {
    name: string;
    subject?: XmlSubject[];
}

export interface XmlSubjectVariable {
    name: string;
    section: string;
    visible: XmlSubjectBoolean;
    status: XmlSubjectBoolean;
    locked: XmlSubjectBoolean;
    type: XmlVariableEnum;
    visibility: XmlVisibilityEnum;
    hasCalculation?: XmlSubjectBoolean;
    value?: [XmlStringElement];
    validation: XmlSubjectValidation[];
    option?: XmlSubjectVariableOption[];
    isCardinalityReached?: XmlSubjectBoolean;
    "can-create"?: XmlSubjectBoolean;
}

export interface XmlSubjectValidation {
    name: string;
    status: XmlSubjectBoolean;
    type?: XmlSubjectValidationMessageEnum;
    message?: [XmlStringElement];
}

export interface XmlSubjectVariableOption {
    value: string;
    visible: "0" | "1";
}

export interface XmlSubjectResponse {
    data: {
        subject: XmlSubject[];
    };
}
