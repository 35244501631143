<div class="survey-environment-widget__environment">
    <div
        [class.survey-environment-widget__environment--test]="color === SurveyEnvironmentColor.Test"
        [class.survey-environment-widget__environment--accept]="
            color === SurveyEnvironmentColor.Accept
        "
        [class.survey-environment-widget__environment--prod]="color === SurveyEnvironmentColor.Prod"
        [lgTooltip]="$any(tooltip)"
    >
        {{ environment }}
    </div>
</div>
