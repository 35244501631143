import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";

export enum SurveyEnvironmentColor {
    Test,
    Accept,
    Prod
}

@Component({
    selector: "survey-environment-widget",
    templateUrl: "./survey-environment-widget.component.html",
    styleUrls: ["./survey-environment-widget.component.scss"],
    host: {
        class: "survey-environment-widget",
        "[hidden]": "!environment"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SurveyEnvironmentWidgetComponent {
    @Input() environment?: string;
    @Input() tooltip?: string;
    @Input() color: SurveyEnvironmentColor = SurveyEnvironmentColor.Test;

    SurveyEnvironmentColor = SurveyEnvironmentColor;
}
