<a
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: _page > 1 ? _page - 1 : 1 }"
    [class.disabled]="_page === 1"
>
    <lg-icon icon="icon-arrow-left" [inline]="true"></lg-icon>
</a>
<a
    class="survey-paging__page"
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: 1 }"
    [class.disabled]="_page === 1"
    >1</a
>
<span *ngIf="_page > 3" class="survey-paging__dots">...</span>
<a
    *ngIf="_page > 2"
    class="survey-paging__page"
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: _page - 1 }"
    >{{ _page - 1 }}</a
>
<a
    *ngIf="_page > 1 && _page &lt; totalPages"
    class="survey-paging__page"
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: _page }"
    [class.disabled]="true"
    >{{ _page }}</a
>
<a
    *ngIf="_page &lt; totalPages - 1"
    class="survey-paging__page"
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: _page + 1 }"
    >{{ _page + 1 }}</a
>
<span *ngIf="_page &lt; totalPages - 2" class="survey-paging__dots">...</span>
<a
    *ngIf="totalPages > 1"
    class="survey-paging__page"
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: totalPages }"
    [class.disabled]="_page === totalPages"
    >{{ totalPages }}</a
>
<a
    [routerLink]="['.']"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ page: totalPages > _page ? _page + 1 : totalPages > 0 ? totalPages : 1 }"
    [class.disabled]="_page >= totalPages"
>
    <lg-icon icon="icon-arrow-right" [inline]="true" [clickable]="_page &lt; totalPages"></lg-icon>
</a>
