<div class="lg-sidebar-account__information" *ngIf="_user$ | async as userProfile">
    <div>
        <div class="lg-sidebar-account__information--profile">
            <div class="lg-sidebar-account__information--profile_user">
                <div class="lg-sidebar-account__information--profile_username">
                    {{ userProfile.firstName }} {{ userProfile.lastName }}
                </div>
                <div class="lg-sidebar-account__information--profile_position">
                    {{ userProfile.email }}
                </div>
            </div>
        </div>
    </div>

    <div class="lg-sidebar-account__information__devMode">
        <div
            class="lg-sidebar-account__information__devMode__group lg-sidebar-account__information__devMode__group--logout"
        >
            <div class="lg-sidebar-account__information__devMode__group__logout">
                <a (click)="_logout($event)"
                    ><lg-icon [icon]="'icon-log-out'" [inline]="true"></lg-icon
                    >{{ "FW.LOGOUT" | lgTranslate }}</a
                >
            </div>
        </div>
    </div>
</div>

<div class="lg-sidebar-account__information">
    <div class="lg-sidebar-menu__language-switch">
        <div class="lg-sidebar-menu__language-switch__header">
            {{ "FW._Language_switch.Language" | lgTranslate }}
        </div>
        <lg-language-switch *ngIf="(_languageIsLocked | async) === false"></lg-language-switch>
        <lg-button
            *ngIf="_languageIsLocked | async"
            class="disabled-language-button"
            buttonClass="button--condensed button--blue"
            [textLc]="currentLanguageLc"
            [isDisabled]="true"
            icon="icon-lock"
            iconColor="#00747c"
        ></lg-button>
    </div>
</div>
