import { XmlSubjectDataset } from "./get-subject-response";

export type UpdateSubjectResponseImpl =
    | {
          data: {
              project: UpdateSubjectResponseProject[];
          };
          commands: UpdateSubjectCommand[];
      }
    | {
          error: string;
      };

export interface UpdateSubjectResponseProject {
    name: string;
    dataset: XmlSubjectDataset[];
}

export interface UpdateSubjectCommand {
    action: string;
    control: string;
    args: string[];
}
