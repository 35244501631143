import {
    AutoincrementType,
    ControlType,
    ControlVariant,
    OptionVisibility,
    SearchScope,
    VariableType,
    VariableVisibility
} from "../../models";
import {
    XmlAutoincrementEnum,
    XmlControlEnum,
    XmlControlTypeEnum,
    XmlOptionVisibilityEnum,
    XmlSearchScopeEnum,
    XmlVariableEnum,
    XmlVisibilityEnum
} from "../responses";

export function convertControlType(source: XmlControlEnum | null | undefined): ControlType | null {
    if (source == null) return null;
    switch (source) {
        case XmlControlEnum.Autocomplete:
            return ControlType.Autocomplete;
        case XmlControlEnum.Radio:
            return ControlType.Radio;
        case XmlControlEnum.Select:
            return ControlType.Select;
        case XmlControlEnum.Textarea:
            return ControlType.Textarea;
        default:
            throw new Error("Unknown control type " + source);
    }
}

export function convertVariableType(source: XmlVariableEnum): VariableType {
    switch (source) {
        case XmlVariableEnum.Dataset:
            return VariableType.Dataset;
        case XmlVariableEnum.Date:
            return VariableType.Date;
        case XmlVariableEnum.DateTime:
            return VariableType.DateTime;
        case XmlVariableEnum.Decimal:
            return VariableType.Decimal;
        case XmlVariableEnum.Encrypted:
            return VariableType.Encrypted;
        case XmlVariableEnum.Integer:
            return VariableType.Integer;
        case XmlVariableEnum.Paragraph:
            return VariableType.Paragraph;
        case XmlVariableEnum.Varchar:
            return VariableType.Varchar;
        case XmlVariableEnum.Reference:
            return VariableType.Reference;
        case XmlVariableEnum.ExternalDataset:
            return VariableType.ExternalDataset;
        default:
            throw new Error("Unknown variable type " + source);
    }
}

export function convertControlVariant(
    source: XmlControlTypeEnum | null | undefined
): ControlVariant | null {
    if (source == null) return null;
    switch (source) {
        case XmlControlTypeEnum.Horizontal:
            return ControlVariant.Horizontal;
        case XmlControlTypeEnum.Vertical:
            return ControlVariant.Vertical;
        case XmlControlTypeEnum.Count:
            return ControlVariant.Count;
        default:
            throw new Error("Unknown control variant " + source);
    }
}

export function convertSearchScope(source: XmlSearchScopeEnum | null | undefined): SearchScope {
    if (source == null) return SearchScope.Organisation;
    switch (source) {
        case XmlSearchScopeEnum.Organisation:
            return SearchScope.Organisation;
        case XmlSearchScopeEnum.Project:
            return SearchScope.Project;
        case XmlSearchScopeEnum.Subject:
            return SearchScope.Subject;
        default:
            throw new Error("Unknown search scope " + source);
    }
}

export function convertAutoIncrementType(
    source: XmlAutoincrementEnum | null | undefined
): AutoincrementType {
    if (source == null) return AutoincrementType.Project;
    switch (source) {
        case XmlAutoincrementEnum.Organisation:
            return AutoincrementType.Organisation;
        case XmlAutoincrementEnum.Project:
            return AutoincrementType.Project;
        default:
            throw new Error("Unknown autoincrement type " + source);
    }
}

export function convertOptionVisibility(
    source: XmlOptionVisibilityEnum | null | undefined
): OptionVisibility | null {
    if (source == null) return null;
    switch (source) {
        case XmlOptionVisibilityEnum.Disabled:
            return OptionVisibility.Disabled;
        case XmlOptionVisibilityEnum.Hidden:
            return OptionVisibility.Hidden;
        default:
            throw new Error("Unknown option visibility " + source);
    }
}

export function convertVariableVisibility(
    source: XmlVisibilityEnum | null | undefined
): VariableVisibility | null {
    if (source == null) return null;
    switch (source) {
        case XmlVisibilityEnum.Hidden:
            return VariableVisibility.Hidden;
        case XmlVisibilityEnum.Readonly:
            return VariableVisibility.Readonly;
        case XmlVisibilityEnum.Visible:
            return VariableVisibility.Visible;
        default:
            throw new Error("Unknown variable visibility " + source);
    }
}
