const formatRegex = new RegExp("([a-zA-Z]+)(\\W)?", "g");

export function fromPhpDateFormat(phpFormat: string): string {
    let match = formatRegex.exec(phpFormat);
    let result = "";
    let failed = false;
    while (match !== null) {
        switch (match[1]) {
            case "d":
                result += "dd";
                break;
            case "j":
                result += "d";
                break;
            case "m":
                result += "MM";
                break;
            case "n":
                result += "M";
                break;
            case "Y":
                result += "yyyy";
                break;
            case "y":
                result += "yy";
                break;
            case "H":
                result += "HH";
                break;
            case "G":
                result += "H";
                break;
            case "i":
                result += "mm";
                break;
            case "s":
                result += "ss";
                break;
            default:
                failed = true;
                break;
        }
        if (match[2]) {
            result += match[2];
        }
        match = formatRegex.exec(phpFormat);
    }
    if (failed) console.error("PHP format not supported: ", phpFormat);
    return result;
}
