import { SurveyError, SurveyErrorCode } from "../survey-rest-api.types";

export function convertSurveyError(error: any): SurveyError {
    if ("code" in error && "error" in error) return error;

    if (error.error === "Mrdm\\Common\\Survey\\Security\\LoginException") {
        return {
            code: SurveyErrorCode.NotAuthorized,
            error: "User is not authorized to use Survey"
        };
    }
    if (error.status && error.status >= 400) {
        if (
            error.status === 500 &&
            error.error?.error === "No survey application access found in auth0"
        ) {
            return {
                code: SurveyErrorCode.NotAuthorized,
                error: "User is not authorized to use Survey",
                statusCode: 500,
                response: error.error
            };
        }
        if (error.status === 500 && error.error?.error?.indexOf("Access denied for user") > 0) {
            return {
                code: SurveyErrorCode.ProjectNotAvailable,
                error: "Project is not available",
                statusCode: 500,
                response: error.error
            };
        }
        let errorMessage: string;
        if (error.error) {
            errorMessage = error.error.error || error.error.toString();
        } else {
            errorMessage = error.message || "Unknown error";
        }
        return {
            code: error.status === 403 ? SurveyErrorCode.NotLoggedIn : SurveyErrorCode.Generic,
            statusCode: error.status,
            error: errorMessage,
            response: error.error ?? error.message ?? error.statusText
        };
    }
    return {
        code: SurveyErrorCode.Generic,
        error: "Unknown error"
    };
}
