import { Injectable } from "@angular/core";

import { LgSnackbarService } from "@logex/framework/ui-core";

@Injectable({ providedIn: "root" })
export class NotificationService {
    constructor(private _lgScnackbarService: LgSnackbarService) {}

    showNotification(
        titleLc: string,
        textLc: string,
        { width = 640, callback }: { width?: number; callback?: () => Promise<boolean> } = {}
    ): void {
        this._lgScnackbarService.show({
            titleLc,
            textLc,
            autoHide: false,
            width,
            buttons: [
                {
                    text: "OK",
                    callback: callback ?? (() => Promise.resolve(true))
                }
            ]
        });
    }
}
