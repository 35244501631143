import { Injectable } from "@angular/core";

import { IAuthenticationService, UserIdentity } from "@logex/framework/lg-application";
import { firstValueFrom } from "rxjs";

import { Auth0AuthorizationService } from "./auth0-authorization.service";

@Injectable()
export class Auth0AuthenticationService implements IAuthenticationService {
    get loggedIn(): boolean {
        return this._authorizationService.loggedIn ?? false;
    }

    get user(): UserIdentity {
        const user = this._authorizationService.currentUserProfile;
        return {
            id: user?.email ?? "",
            login: user?.email ?? "",
            name: user?.name ?? ""
        };
    }

    constructor(private _authorizationService: Auth0AuthorizationService) {}

    login(): Promise<boolean> {
        return this._authorizationService.login();
    }

    isLoggedIn(): Promise<boolean> {
        return firstValueFrom(this._authorizationService.isAuthenticated$);
    }

    logout(): Promise<void> {
        return this._authorizationService.logout();
    }
}
