import * as _ from "lodash";

import { ControlType } from "./control-type";
import { DatasetDefinition } from "./dataset-definition";
import { LayoutDefinition } from "./layout-definition";
import { ProjectLocalizations } from "./project-localizations";
import { ProjectResources } from "./project-resources";
import { ReportOverrides } from "./report-overrides";
import { StatementDefinition } from "./statement-definition";
import { VariableDefinition } from "./variable-definition";

export interface ProjectDefinition {
    name: string;
    dbName: string;
    multicenter: boolean; // default false
    defaultLanguage: string | null;
    label: string | null;
    description: string | null;
    logo: string | null;
    organisation: string | null;
    email: string | null;
    colors: _.Dictionary<string>;
    layout: LayoutDefinition;
    fixedMenu: boolean;
    multilevelMenu: number;
    defaultControl: ControlType | null;
    filter: VariableDefinition | null;
    statementToAgree: StatementDefinition | null;
    datasets: DatasetDefinition[];
    rootDataset: string;
    localizations: ProjectLocalizations;
    legacyLabelSplit?: number;
    skipEmptyCreationDialog: boolean;
    hideSubjectCountInSubjectList: boolean;
    reports: ReportOverrides;
    resources?: ProjectResources;
}
