import _ from "lodash";
import type { DatasetLocalizations } from "./dataset-localizations";
import type { ProjectLocalizations } from "./project-localizations";
import type { VariableLocalizations } from "./variable-localizations";
import type { ReportOverrides } from "./report-overrides";
import type { ProjectResources } from "./project-resources";
export interface OptionImageDefinitionOverride {
    src: string;
    title: string | null;
    titleColor: string | null;
    dotColor: string | null;
    width: number | null;
    height: number | null;
}
export interface OptionDefinitionOverride {
    image?: OptionImageDefinitionOverride;
}
export interface VariableDefinitionOverride {
    localizations?: VariableLocalizations;
    optionset?: {
        options?: _.Dictionary<OptionDefinitionOverride>;
    };
    isBarcodeScannerInput: boolean;
    useResourceIcon?: string;
    isUnskippable?: boolean;
}

export interface SectionDefinitionOverride {
    showAddSubjectButton?: boolean;
}
export interface ProjectDatasetOverride {
    localizations?: DatasetLocalizations;
    variables?: _.Dictionary<VariableDefinitionOverride>;
    sections?: _.Dictionary<SectionDefinitionOverride>;
    indelible: boolean;
    layoutHideAddSubjectInSections: string[];
}

export interface ProjectOverrides {
    localizations?: ProjectLocalizations;
    datasets?: _.Dictionary<ProjectDatasetOverride>;
    legacyLabelSplit?: number;
    skipEmptyCreationDialog?: boolean;
    hideSubjectCountInSubjectList: boolean;
    reports?: ReportOverrides;
    resources?: ProjectResources;
}
