import _ from "lodash";

export interface DefaultValuesOption {
    value: string;
    visibility: string;
}
export interface DefaultValuesOptionVisibility {
    name: string;
    option: DefaultValuesOption | DefaultValuesOption[];
}
export interface GetDefaultValuesResponse {
    defaults: _.Dictionary<string | number | null> | [];
    visibility: DefaultValuesOptionVisibility[];
}
