import { SurveyResource } from "../services/responses";

export interface ProjectMetadata extends SurveyResource {
    parentUri: string | null;
    product: string;
    active: boolean;
    selectable: boolean;
    uiV1: boolean;
    uiV2: boolean;
    hasYearPopup: boolean;
}
