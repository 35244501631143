import { Inject, Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    InterceptorChainDefinition,
    INTERCEPTOR_CHAIN_DEFINITION,
    META_INTERCEPTOR_CHAIN
} from "./meta-interceptor.types";

class MetaInterceptorHandler implements HttpHandler {
    constructor(private _next: HttpHandler, private _interceptor: HttpInterceptor) {}

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this._interceptor.intercept(req, this._next);
    }
}

interface ChainInterceptors {
    [id: string]: HttpInterceptor[];
}

@Injectable()
export class MetaInterceptorService implements HttpInterceptor {
    private _chainDefinitions: InterceptorChainDefinition;
    private _interceptors: ChainInterceptors = {};

    constructor(
        @Inject(INTERCEPTOR_CHAIN_DEFINITION) _definitions: InterceptorChainDefinition[],
        private _injector: Injector
    ) {
        this._chainDefinitions = _definitions.reduceRight(
            (all, current) => ({ ...all, ...current }),
            {}
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const metaId = req.context.get(META_INTERCEPTOR_CHAIN);
        if (metaId === null) return next.handle(req);

        let interceptors = this._interceptors[metaId];
        if (interceptors === undefined) {
            const chainDefinition = this._chainDefinitions[metaId];
            if (chainDefinition === undefined)
                throw new Error("Unknown meta interceptor chain " + metaId);
            interceptors = this._interceptors[metaId] = chainDefinition.map(iType =>
                this._injector.get<HttpInterceptor>(iType)
            );
        }

        const chain = interceptors.reduceRight(
            (next2, interceptor) => new MetaInterceptorHandler(next2, interceptor),
            next
        );
        return chain.handle(req);
    }
}
