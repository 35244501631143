import { Directive, OnDestroy } from "@angular/core";
import { Subject, MonoTypeOperatorFunction } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Auth0AuthorizationService } from "survey-authorization";
import { SurveyRestApiService } from "survey-api";

@Directive()
export class PageComponentBase implements OnDestroy {
    protected readonly _destroyed$ = new Subject<void>();

    constructor(
        protected _auth0Service: Auth0AuthorizationService,
        protected _restApiService: SurveyRestApiService
    ) {}

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil<T>(this._destroyed$);
    }
}
