import { Component, inject } from "@angular/core";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { map } from "rxjs/operators";
import { Auth0AuthorizationService, MrdmAuthService } from "survey-authorization";
import { SurveyAppState } from "../../../shared";

@Component({
    selector: "survey-sidebar-user",
    templateUrl: "./survey-sidebar-user.component.html",
    styleUrls: ["./survey-sidebar-user.component.scss"],
    host: {
        class: "lg-sidebar-account lg-sidebar-panel"
    },
    providers: [...useTranslationNamespace("FW._Sidebar._Account")]
})
export class SurveySidebarUserComponent {
    private _translateService = inject(LgTranslateService);
    private _auth0 = inject(Auth0AuthorizationService);
    private _authService = inject(MrdmAuthService);
    private _appState = inject(SurveyAppState);

    _user$ = this._authService.user$;
    protected _languageIsLocked = this._appState.currentProject$.pipe(
        map(project => {
            return project !== null;
        })
    );

    _logout($event: Event): void {
        $event.stopPropagation();
        $event.preventDefault();
        this._auth0.logout();
    }

    protected get currentLanguageLc(): string {
        return "FW._Language_switch." + this._translateService.getLanguage();
    }
}
