export interface SubjectsValidationReportEntry {
    errorCount: number;
    warningCount: number;
    updated: Date | null;
    validated: Date | null;
    label: string;
    uri: string;
}

export interface SubjectsValidationReport {
    subjects: SubjectsValidationReportEntry[];
    pageNumber: number;
    totalPages: number;
    itemsPerPage: number;
    registeredYear: string;
    years: number[];
}
