import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpContext } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, finalize, map, switchMap } from "rxjs/operators";
import { LgLoaderService } from "@logex/framework/lg-layout";

import { SURVEY_REST_API_URL } from "./survey-rest-api.types";
import {
    CreateSessionResponse,
    GetSessionResponse,
    SurveyResponseError,
    isSurveyResponseError,
    SelectProjectResponse
} from "./responses";
import { META_INTERCEPTOR_CHAIN } from "../utility";

interface CurrentProjectState {
    organizationUri: string;
    projectUri: string;
    checked: boolean;
}

@Injectable({ providedIn: "root" })
export class SurveyRestSessionApiService {
    constructor(
        @Inject(SURVEY_REST_API_URL) private _baseUrl: string,
        private _httpClient: HttpClient,
        private _loader: LgLoaderService
    ) {}

    createSession(authToken: string): Observable<CreateSessionResponse> {
        this._loader.show();
        return this._httpClient
            .post<CreateSessionResponse>(
                `${this._baseUrl}/session`,
                {},
                { headers: { Authorization: `Bearer ${authToken}` } }
            )
            .pipe(
                finalize(() => {
                    this._loader.hide();
                })
            )
            .pipe(
                switchMap(response => {
                    // backend returns 200 on failure because why not
                    if ((response as any).error) {
                        return throwError(response);
                    } else {
                        return of(response);
                    }
                })
            );
    }

    getSessionInfo(
        authToken: string,
        session: CreateSessionResponse
    ): Observable<GetSessionResponse | null> {
        return this._httpClient
            .get<GetSessionResponse | SurveyResponseError>(
                `${this._baseUrl}/${session.sessionId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "X-Session-Key": session["X-Session-Key"],
                        "X-Session-Token": session["X-Session-Token"]
                    }
                }
            )
            .pipe(
                map(reply => {
                    if (isSurveyResponseError(reply)) return null;
                    return reply;
                })
            );
    }

    reapplyProject(state: CurrentProjectState): Observable<SelectProjectResponse> {
        return this._httpClient.post<SelectProjectResponse>(
            `${this._baseUrl}/sessionId/select-project`,
            {
                organisation: state.organizationUri,
                project: state.projectUri,
                agreed: state.checked ? "on" : "off"
            },
            {
                context: new HttpContext().set(META_INTERCEPTOR_CHAIN, "SURVEY_SESSION")
            }
        );
    }

    reapplyLocks(): Observable<void> {
        return this._httpClient
            .post<void>(
                `${this._baseUrl}/sessionId/locks/update-session`,
                {},
                {
                    context: new HttpContext().set(META_INTERCEPTOR_CHAIN, "SURVEY_SESSION")
                }
            )
            .pipe(catchError(() => of<void>()));
    }

    extractProject(body: any): any {
        return <CurrentProjectState>{
            organizationUri: body.organisation,
            projectUri: body.project,
            checked: body.agreed === "on"
        };
    }
}
