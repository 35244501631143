import { InjectionToken } from "@angular/core";

export type OrganizationIdentifier = "Global" | "Cic" | "Uri" | "Agb" | undefined;

export interface AuthorizationPermission {
    product: string;
    permissions: string[];
}

export interface AuthorizationOrganization {
    organizationId: number;
    agbCode: string | null;
    cicCode: number | null;
    mrdmUri: string | null;
    name: string;
    position: string;
}

export interface AuthorizationExtendedPermission
    extends AuthorizationPermission,
        AuthorizationOrganization {
    applicationInstance: string;
}

export interface AuthorizationProfile {
    accountId: number;
    email: string;
    firstName: string;
    lastName: string;
    organizations: AuthorizationOrganization[];
    isDisabled: boolean;
    isInternal: boolean;
    metadata: Record<string, string>;
}

export const MRDM_AUTH_API_URL = new InjectionToken<Promise<string>>("MrdmAuthApiUrl");
