import { Component } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { SurveyAppState } from "@shared";
import { Auth0AuthorizationService } from "survey-authorization";
import { AuthUser } from "../../shared/types";

@Component({
    selector: "app-access-denied",
    templateUrl: "./access-denied.component.html",
    styleUrls: ["./access-denied.component.scss"],
    providers: [useTranslationNamespace("APP._AccessDenied")]
})
export class AccessDeniedComponent {
    constructor(
        private _auth0Authorization: Auth0AuthorizationService<AuthUser>,
        private _appState: SurveyAppState
    ) {
        this._appState.markInitializationDone();
    }

    _logout(): void {
        this._auth0Authorization.logout();
    }
}
