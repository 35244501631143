import { LayoutDefinition } from "./layout-definition";
import { VariableDefinition } from "./variable-definition";

export interface SectionDefinition {
    name: string;
    // plugin?: string;
    // "promise-tag"?: string;
    layout: LayoutDefinition;
    label: string;
    description?: string | null;
    generateLink: string | null;
    sessionLink: string | null;
    // condition?: [XmlConditionType];
    // lock?: [XmlLockType];
    variables: VariableDefinition[];
    showAddSubjectButton?: boolean;
}
