import * as _ from "lodash";
import { HttpClient, HttpContext, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
    ExternalDataset,
    ExternalOptionset,
    ProjectDefinition,
    ProjectOverrides,
    ProjectPermissions,
    ProjectSelection,
    SearchResult,
    StatementDefinition,
    SubjectState,
    SubjectStateDataset
} from "../models";
import { META_INTERCEPTOR_CHAIN } from "../utility/index";
import { convertProjectDefinition } from "./helpers/convert-project-definition";
import { convertSubject, convertSubjectDataset } from "./helpers/convert-subject";
import {
    CreateSubjectResponse,
    CreateSubjectResponseImpl,
    FindSubjectByKeyResponse,
    GetExternalDatasetResponse,
    GetDefaultValuesResponse,
    GetProjectResponse,
    GetSubjectListResponse,
    GetValidationReportTokenResponse,
    LockSubjectResponse,
    ProjectSelectionResponse,
    SearchSubjectsResponse,
    SelectProjectResponse,
    SubjectHeartbeatResponse,
    UpdateSubjectResponseImpl,
    ValidatePotentialSubjectResponse,
    XmlSubjectResponse,
    DefaultValuesOptionVisibility
} from "./responses";
import { convertSurveyError } from "./helpers/convert-survey-error";
import { SAVE_PROJECT, SURVEY_REST_API_URL } from "./survey-rest-api.types";
import { extendProjectDefinition } from "./helpers/extend-project-definition";

@Injectable({ providedIn: "root" })
export class SurveyRestApiService {
    constructor(
        @Inject(SURVEY_REST_API_URL) private _baseUrl: string,
        private _httpClient: HttpClient
    ) {}

    getProjectSelection(): Observable<ProjectSelection> {
        return this._doGet<ProjectSelectionResponse>("sessionId/select-project").pipe(
            map(response => ({
                organizations: response.organisations,
                projects: _.mapValues(response.projects, projects =>
                    projects.map(project => ({
                        uri: project.uri,
                        label: project.label,
                        product: project.properties.product,
                        parentUri: project.properties.parent_uri,
                        active: project.properties.active === "1",
                        hasYearPopup: project.properties.has_year_popup !== 0,
                        selectable: project.properties.selectable === "1",
                        uiV1: project.properties.ui_v1_enabled === "1",
                        uiV2: project.properties.ui_v2_enabled === "1"
                    }))
                ),
                selected: {
                    organizationUri: response.selected.organisation,
                    projectUri: response.selected.project
                }
            }))
        );
    }

    selectProject(
        organizationUri: string,
        projectUri: string,
        checked: boolean
    ): Observable<SelectProjectResponse> {
        return this._doPost<SelectProjectResponse>(
            "sessionId/select-project",
            {
                organisation: organizationUri,
                project: projectUri,
                agreed: checked ? "on" : "off"
            },
            undefined,
            new HttpContext().set(SAVE_PROJECT, true)
        );
    }

    getProjectDefinition(
        organisationUri: string,
        projectUri: string,
        language: string
    ): Observable<ProjectDefinition> {
        return forkJoin([
            this._doGet<GetProjectResponse>(`sessionId/${organisationUri}/${projectUri}`).pipe(
                map(def => {
                    if ("error" in def) {
                        throw new Error(def.error);
                    }
                    return convertProjectDefinition(def.project[0], language);
                })
            ),
            this._doGet<ProjectOverrides | null>(`overrides/${projectUri}`).pipe(
                catchError(() => of(null))
            )
        ]).pipe(
            map(([definition, overrides]) => {
                if (!overrides) return definition;
                return extendProjectDefinition(definition, overrides);
            })
        );
    }

    getProjectStatement(
        organisationUri: string,
        projectUri: string
    ): Observable<StatementDefinition | null> {
        return this._doGet<StatementDefinition>(
            `sessionId/${organisationUri}/${projectUri}/select-project/statement`
        );
    }

    getProjectPermissions(
        organisationUri: string,
        projectUri: string
    ): Observable<ProjectPermissions> {
        return this._doGet<{ permissions: ProjectPermissions }>(
            `sessionId/${organisationUri}/${projectUri}/permissions`
        ).pipe(map(response => response.permissions));
    }

    getSubjectList(
        organizationUri: string,
        projectUri: string,
        pageNumber: number,
        pageSize: number
    ): Observable<GetSubjectListResponse> {
        return this._doGet<GetSubjectListResponse>(
            `sessionId/${organizationUri}/${projectUri}/subjectlist`,
            {
                pageNumber: pageNumber.toString(),
                pageSize: pageSize.toString()
            }
        );
    }

    getSubject(
        organizationUri: string,
        projectUri: string,
        dataset: string,
        subjectUri: string
    ): Observable<SubjectState> {
        return this._doGet<XmlSubjectResponse>(
            `sessionId/${organizationUri}/${projectUri}/${dataset}/${subjectUri}`
        ).pipe(
            map(response => {
                if (response.data.subject.length === 0)
                    throw new Error("SubjectDoesNotExistsException");
                return convertSubject(response.data.subject[0], dataset);
            })
        );
    }

    createSubject(
        organizationUri: string,
        projectUri: string,
        dataset: string,
        variables: _.Dictionary<string>,
        parentDatasets?: string[],
        parentSubjects?: string[]
    ): Observable<CreateSubjectResponse> {
        let datasetPrefix = "";
        let subject = "";
        if (parentDatasets || parentSubjects) {
            if (
                !parentDatasets ||
                !parentSubjects ||
                parentDatasets.length !== parentSubjects.length
            ) {
                throw new Error("Inconsistent parent parameters");
            }
            datasetPrefix = parentDatasets.join(".") + ".";
            subject = "/" + parentSubjects.join(".");
        }

        return this._doPost<CreateSubjectResponseImpl>(
            `sessionId/${organizationUri}/${projectUri}/${datasetPrefix}${dataset}${subject}`,
            variables
        ).pipe(
            map(response => ({
                section: response.section,
                datasetUris: response.datasetUri.split("."),
                subjectUris: response.subjectUri.split("."),
                isNew: response.isNew
            }))
        );
    }

    updateSubject(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectsUris: string[],
        variableName: string,
        variableValue: string
    ): Observable<SubjectStateDataset[]> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectsUris.join(".");

        return this._doPut<UpdateSubjectResponseImpl>(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}/${variableName}`,
            {
                value: variableValue
            }
        ).pipe(
            map(response => {
                if (!response) throw new Error("Unknown exception");
                if ("error" in response) {
                    throw response;
                }
                const project = response.data.project.find(p => p.name === projectUri);
                if (project === undefined) {
                    throw new Error("Unknown subject");
                }

                return project.dataset.map(convertSubjectDataset);
            })
        );
    }

    copySubject(
        organizationUri: string,
        projectUri: string,
        parentDatasets: string[],
        parentSubjects: string[]
    ): Observable<CreateSubjectResponse> {
        if (parentDatasets.length !== parentSubjects.length) {
            throw new Error("Inconsistent parent parameters");
        }
        const dataset = parentDatasets.join(".");
        const subject = parentSubjects.join(".");

        return this._doPost<CreateSubjectResponseImpl>(
            `sessionId/${organizationUri}/${projectUri}/${dataset}/${subject}/copy`,
            {}
        ).pipe(
            map(response => ({
                section: response.section,
                datasetUris: response.datasetUri.split("."),
                subjectUris: response.subjectUri.split("."),
                isNew: response.isNew
            }))
        );
    }

    getExternalOptionset(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectsUris: string[],
        variableName: string,
        search?: string | null | undefined
    ): Observable<ExternalOptionset> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectsUris.join(".");
        return this._doGet<ExternalOptionset>(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}/externalOptionset`,
            search
                ? {
                      variable: variableName,
                      q: search
                  }
                : {
                      variable: variableName
                  }
        );
    }

    saveExternalOptionsetChoice(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectsUris: string[],
        variableName: string,
        rowId: string
    ): Observable<SubjectStateDataset[]> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectsUris.join(".");

        return this._doPut<UpdateSubjectResponseImpl>(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}/externalOptionset`,
            {
                variable: variableName,
                dataRowId: rowId
            }
        ).pipe(
            map(response => {
                if (!response) throw new Error("Unknown exception");
                if ("error" in response) {
                    throw response;
                }
                const project = response.data.project.find(p => p.name === projectUri);
                if (project === undefined) {
                    throw new Error("Unknown subject");
                }

                return project.dataset.map(convertSubjectDataset);
            })
        );
    }

    deleteExternalOptionsetChoice(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectsUris: string[],
        variableName: string,
        rowId: string
    ): Observable<SubjectStateDataset[]> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectsUris.join(".");

        return this._doDelete<UpdateSubjectResponseImpl>(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}/externalOptionset`,
            {
                variable: variableName
            }
        ).pipe(
            map(response => {
                if (!response) throw new Error("Unknown exception");
                if ("error" in response) {
                    throw response;
                }
                const project = response.data.project.find(p => p.name === projectUri);
                if (project === undefined) {
                    throw new Error("Unknown subject");
                }

                return project.dataset.map(convertSubjectDataset);
            })
        );
    }

    searchSubjects(
        organizationUri: string,
        projectUri: string,
        search: string
    ): Observable<SearchResult> {
        return this._doPost<SearchSubjectsResponse>(
            `sessionId/${organizationUri}/${projectUri}/search`,
            {
                search
            }
        ).pipe(
            map(result => ({
                total: result.total,
                subjects: result.organisation.map(entry => ({
                    subjectUris: entry.subject_uri.split("."),
                    datasetUris: entry.dataset_uri.split("."),
                    variableName: entry.variable_uri,
                    organizationUri: entry.organisation_uri,
                    label: entry.label,
                    otherOrganization: entry.organisation_uri !== organizationUri
                }))
            }))
        );
    }

    subjectHeartbeat(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectUris: string[],
        section?: string | null
    ): Observable<SubjectHeartbeatResponse> {
        let path = [
            organizationUri,
            projectUri,
            datasetUris.join("."),
            subjectUris.join("."),
            section
        ];
        const endIndex = path.findIndex(seg => seg == null);
        if (endIndex !== -1) {
            path = path.slice(0, endIndex);
        }
        const url = path.join("/");
        return this._doPost<SubjectHeartbeatResponse>(`heartbeat?uri=sessionId/${url}`, {});
    }

    reapplyLocks(): Observable<void> {
        return this._doPost<void>(`sessionId/locks/update-session`, {}).pipe(
            catchError(error => of<void>())
        );
    }

    lockSubject(subjectUri: string): Observable<boolean> {
        return this._doPost<LockSubjectResponse>(`sessionId/lock`, {
            subject: subjectUri
        }).pipe(
            catchError(error => {
                if (
                    error.statusCode === HttpStatusCode.BadRequest &&
                    error.response &&
                    "success" in error.response
                ) {
                    return of(error.response as LockSubjectResponse);
                }
                throw error;
            }),
            map(response => response.success)
        );
    }

    unlockSubject(subjectUri: string): Observable<boolean> {
        return this._doPost<LockSubjectResponse>(`sessionId/unlock`, {
            subject: subjectUri
        }).pipe(
            catchError(error => {
                if (
                    error.statusCode === HttpStatusCode.BadRequest &&
                    error.response &&
                    "success" in error.response
                ) {
                    return of(error.response as LockSubjectResponse);
                }
                throw error;
            }),
            map(response => response.success)
        );
    }

    validatePotentialSubject<T extends _.Dictionary<string>>(
        organizationUri: string,
        projectUri: string,
        datasetUri: string,
        variables: T
    ): Observable<ValidatePotentialSubjectResponse> {
        return this._doPost<ValidatePotentialSubjectResponse>(
            `sessionId/${organizationUri}/${projectUri}/subjectmanager`,
            {
                ...variables,
                dataset: datasetUri
            }
        );
    }

    findSubjectByKeyVariables<T extends _.Dictionary<string>>(
        organizationUri: string,
        projectUri: string,
        variables: T
    ): Observable<FindSubjectByKeyResponse<T>> {
        return this._doGet<FindSubjectByKeyResponse<T>>(
            `sessionId/${organizationUri}/${projectUri}/subjectmanager`,
            variables
        );
    }

    deleteSubject(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectUris: string[]
    ): Observable<void> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectUris.join(".");
        return this._doDelete<any>(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}`,
            null
        );
    }

    downloadAsPdf(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectUris: string[]
    ): Observable<Blob> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectUris.join(".");
        return this._doBlobGet(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}/print`
        );
    }

    getValidationReportToken(organizationUri: string, projectUri: string): Observable<string> {
        return this._doGet<GetValidationReportTokenResponse>(
            `sessionId/${organizationUri}/${projectUri}/entry-report`
        ).pipe(map(response => response.token));
    }

    getExternalDataset(
        organizationUri: string,
        projectUri: string,
        datasetUris: string[],
        subjectUris: string[],
        variableName: string
    ): Observable<ExternalDataset> {
        const datasetUri = datasetUris.join(".");
        const subjectUri = subjectUris.join(".");
        return this._doGet<GetExternalDatasetResponse>(
            `sessionId/${organizationUri}/${projectUri}/${datasetUri}/${subjectUri}/externalDataset`,
            {
                variable: variableName
            }
        ).pipe(
            map(([response]) => {
                if (_.isArray(response)) {
                    return {
                        total: 0,
                        section: "",
                        entries: []
                    };
                } else {
                    return {
                        total: response.total,
                        section: response.section,
                        entries: Object.keys(response.cols).map(uri => ({
                            uri,
                            label: response.cols[uri],
                            oldUri: response.full[uri]
                        }))
                    };
                }
            })
        );
    }

    getDefaultValues(
        organizationUri: string,
        projectUri: string,
        dataset: string,
        parentDatasets?: string[],
        parentSubjects?: string[]
    ): Observable<{
        defaults: _.Dictionary<string | null>;
        visibility: DefaultValuesOptionVisibility[];
    }> {
        let datasetPrefix = "";
        let subject = "-";
        if (parentDatasets || parentSubjects) {
            if (
                !parentDatasets ||
                !parentSubjects ||
                parentDatasets.length !== parentSubjects.length
            ) {
                throw new Error("Inconsistent parent parameters");
            }
            datasetPrefix = parentDatasets.join(".") + ".";
            subject = parentSubjects.join(".");
        }
        return this._doGet<GetDefaultValuesResponse>(
            `sessionId/${organizationUri}/${projectUri}/${datasetPrefix}${dataset}/${subject}/default-values`
        ).pipe(
            map(response => {
                return {
                    defaults: _.isArray(response.defaults)
                        ? {}
                        : _.mapValues(response.defaults, key => {
                              return key?.toString() ?? null;
                          }),
                    visibility: response.visibility ?? []
                };
            })
        );
    }

    private _doGet<TResponse>(
        url: string,
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              }
    ): Observable<TResponse> {
        return this._httpClient
            .get<TResponse>(`${this._baseUrl}/${url}`, {
                context: new HttpContext().set(META_INTERCEPTOR_CHAIN, "SURVEY_SESSION"),
                params
            })
            .pipe(catchError(error => throwError(convertSurveyError(error))));
    }

    private _doPost<TResponse>(
        url: string,
        body: any,
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              },
        context?: HttpContext
    ): Observable<TResponse> {
        return this._httpClient
            .post<TResponse>(`${this._baseUrl}/${url}`, body, {
                context: (context ?? new HttpContext()).set(
                    META_INTERCEPTOR_CHAIN,
                    "SURVEY_SESSION"
                ),
                params
            })
            .pipe(catchError(error => throwError(convertSurveyError(error))));
    }

    private _doPut<TResponse>(
        url: string,
        body: any,
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              }
    ): Observable<TResponse> {
        return this._httpClient
            .put<TResponse>(`${this._baseUrl}/${url}`, body, {
                context: new HttpContext().set(META_INTERCEPTOR_CHAIN, "SURVEY_SESSION"),
                params
            })
            .pipe(catchError(error => throwError(convertSurveyError(error))));
    }

    private _doDelete<TResponse>(
        url: string,
        body: any,
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              }
    ): Observable<TResponse> {
        return this._httpClient
            .delete<TResponse>(`${this._baseUrl}/${url}`, {
                // headers: {
                //     "Content-Type": contentType ?? "application/json"
                // },
                context: new HttpContext().set(META_INTERCEPTOR_CHAIN, "SURVEY_SESSION"),
                params,
                body
            })
            .pipe(catchError(error => throwError(convertSurveyError(error))));
    }

    private _doBlobGet(
        url: string,
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              }
    ): Observable<Blob> {
        return this._httpClient
            .get(`${this._baseUrl}/${url}`, {
                context: new HttpContext().set(META_INTERCEPTOR_CHAIN, "SURVEY_SESSION"),
                params,
                responseType: "blob"
            })
            .pipe(catchError(error => throwError(convertSurveyError(error))));
    }
}
