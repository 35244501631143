export interface ProjectResources {
    icon?: Record<
        string,
        {
            disabled?: boolean;
            src: string;
            tooltip: string;
        }
    >;
}
