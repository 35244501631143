import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { ConfigService } from "./config.service";
import { AppConfiguration } from "./app-configuration";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppStartupService {
    constructor(private _http: HttpClient, private _configService: ConfigService) {}

    initialize(): () => Promise<void> {
        return async () => {
            await this._loadConfig();
        };
    }

    private _loadConfig(): Promise<AppConfiguration> {
        return firstValueFrom(
            this._http
                .get<AppConfiguration>("assets/config/appsettings.json")
                .pipe(tap(c => this._configService.setConfigation(c)))
        );
    }
}
