import type { VariableValidation } from "./variable-validation";
import type { VariableVisibility } from "./variable-visibility";

export interface SubjectVariable {
    uri: string;
    sectionUri: string;
    visible: boolean;
    valid: boolean;
    locked: boolean;
    hasCalculation: boolean;
    canCreate: boolean;
    // type: XmlVariableEnum;
    // todo: can we unify it with visibility higher up?
    visibility: VariableVisibility | null;
    value: string | null;
    isCardinalityReached: boolean | null;
    validationMessages: VariableValidation[];
    hiddenOptions: null | string[];
}
