import type { SubjectStateDataset } from "./subject-state-dataset";
import type { SubjectVariable } from "./subject-variable";

export interface SubjectState {
    uri: string;
    datasetUri: string;
    label: string;
    author: string;
    created: Date | null;
    updated: Date | null;
    organisation: string;
    canDelete: boolean;
    variables: SubjectVariable[];
    childrenDatasets: SubjectStateDataset[];
}
