import { Injectable } from "@angular/core";
import { flatten, uniq } from "lodash";
import { combineLatest, Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { IProduct } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { MrdmAuthApiService } from "./mrdm-auth-api.service";
import { getMrdmProductUrl, MRDM_PRODUCTS } from "./mrdm-products";

@Injectable({
    providedIn: "root"
})
export class MrdmAuthService {
    constructor(
        private _authorizationApiService: MrdmAuthApiService,
        private _translateService: LgTranslateService
    ) {}

    readonly user$ = this._authorizationApiService.getProfile().pipe(shareReplay(1));
    readonly isInternalUser$ = this.user$.pipe(map(profile => profile.isInternal));

    getMrdmProducts$(
        env: string,
        tenant: string,
        productName: string | null
    ): Observable<IProduct[]> {
        return this._getAllowedAppInstances().pipe(
            map(allowedAppInstances =>
                MRDM_PRODUCTS.filter(product =>
                    product.appInstances.some(appInstance =>
                        allowedAppInstances.includes(appInstance)
                    )
                ).map(product => ({
                    id: product.id,
                    name: product.name ?? this._translateService.translate(product.nameLc ?? ""),
                    url: getMrdmProductUrl(env, product, tenant, productName),
                    shortDescription: product.shortDescription,
                    iconClass: product.iconClass
                }))
            ),
            shareReplay(1)
        );
    }

    private _getAllowedAppInstances(): Observable<string[]> {
        const applicationInstances = flatten(MRDM_PRODUCTS.map(product => product.appInstances));
        return this._authorizationApiService.searchPermissions({ applicationInstances }).pipe(
            map(permissions => {
                const appInstances = permissions.map(permission => permission.applicationInstance);
                return uniq(appInstances);
            })
        );
    }
}
