import * as _ from "lodash";
import { inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    forkJoin,
    from,
    Observable,
    of,
    OperatorFunction,
    Subject,
    throwError
} from "rxjs";
import {
    map,
    switchMap,
    takeUntil,
    shareReplay,
    first,
    catchError,
    tap,
    debounceTime,
    filter,
    startWith,
    distinctUntilChanged
} from "rxjs/operators";

import {
    SurveyRestApiService,
    ProjectDefinition,
    ControlType,
    ExternalOptionset,
    SurveyError,
    SurveyErrorCode,
    ProjectSelection,
    OrganizationMetadata,
    ProjectMetadata,
    SurveyValidationReportsApiService,
    AvailableValidationReport,
    DatasetDefinition,
    ProjectPermissions,
    SURVEY_REST_API_URL
} from "survey-api";
import { Title } from "@angular/platform-browser";
import { Router, UrlSerializer } from "@angular/router";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { INavNode, LgMatTrackingService } from "@logex/framework/lg-application";
import { observeOnZone } from "@logex/framework/utilities";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { Auth0AuthorizationService, MrdmAuthService } from "survey-authorization";
import { ConfigService } from "./config.service";
import { isNotNull, MatomoConfiguration } from "@shared";
import { SurveyDataset, SurveySection, SurveyVariable } from "../../authorized/survey-model";

export interface OrganizationProject {
    organization: OrganizationMetadata;
    project: ProjectMetadata;
}

export interface ParentProjectState {
    name: string;
    subjectListName: string | null;
    subjectListUri: string;
    returnToUri: string;
    returnToName: string;
    reports: AvailableValidationReport[] | null;
}

const INACTIVITY_TIMEOUT = 30 * 60; // 30 minutes
const MIN_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = 100;
const DEFAULT_PAGE_SIZE = 10;
const PARENT_STATE_VERSION = 1;

@Injectable({
    providedIn: "root"
})
export class SurveyAppState implements OnDestroy {
    readonly surveyRestApiUrl = inject(SURVEY_REST_API_URL);

    private _currentOrganizationUri: string | null = null;
    private _currentProjectUri: string | null = null;
    private _currentProjectDefinition: ProjectDefinition | null = null;
    private _currentProjectParentUri: string | null = null;
    private readonly _selection$: Observable<ProjectSelection>;
    private readonly _destroyed$ = new Subject<void>();
    private readonly _current = new BehaviorSubject<{
        organizationUri: string;
        projectUri: string;
        definition: ProjectDefinition;
        parentUri: string | null;
    } | null>(null);

    private readonly _current$ = this._current.pipe(filter(isNotNull));
    private _href: string;
    private _approvalKey: string;
    private _pagingKey: string;
    private _validityKey: string;
    private _confirmRegistryKey: string;
    private _validationReportYearKey: string;
    private _imagePrefix!: string;
    private readonly _userActivity = new BehaviorSubject<void>(null as unknown as void);
    private readonly _changingProject = new Subject<void>();
    private readonly _navigation = new BehaviorSubject<INavNode[]>([]);
    private readonly _availableReports$!: Observable<AvailableValidationReport[] | null>;
    private readonly _initFinished = new Subject<void>();
    private readonly _currentProjectPermissions$: Observable<ProjectPermissions | null>;
    private _permissionCache: _.Dictionary<Observable<ProjectPermissions>> = {};

    currentProject$ = this._current.asObservable();

    constructor(
        private _matomo: LgMatTrackingService,
        private _matomoConfiguration: MatomoConfiguration,
        private _restApiService: SurveyRestApiService,
        private _router: Router,
        private _confirmationDialog: LgPromptDialog,
        private _auth0: Auth0AuthorizationService,
        private _authService: MrdmAuthService,
        private _title: Title,
        private _ngZone: NgZone,
        private _appConfiguration: ConfigService,
        private _reportApi: SurveyValidationReportsApiService,
        private _loader: LgLoaderService,
        private _lgTranslate: LgTranslateService,
        private _urlSerializer: UrlSerializer
    ) {
        const bases = document.getElementsByTagName("base");
        if (bases.length) {
            this._href = bases[0].href;
        } else {
            this._href = window.origin;
        }
        this._approvalKey = `${this._href}_approval`;
        this._pagingKey = `${this._href}_paging`;
        this._validityKey = `${this._href}_validity`;
        this._confirmRegistryKey = `${this._href}_confirmRegistry`;
        this._validationReportYearKey = `${this._href}_validationReportYear`;
        this._imagePrefix = this.surveyRestApiUrl + "/images";

        this._checkValidity();

        // TODO: refetch after selection change
        this._selection$ = this.getProjectSelection().pipe(
            shareReplay(1),
            takeUntil(this._destroyed$)
        );

        this._userActivity
            .pipe(
                debounceTime(INACTIVITY_TIMEOUT * 1000),
                observeOnZone(this._ngZone),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                this._router.navigate(["/select"]);
            });

        this._availableReports$ = this.getCurrentAccessGroup$().pipe(
            switchMap(group =>
                this._reportApi.getAvailableReports(group.organization.uri, group.project.uri).pipe(
                    startWith("loading"),
                    catchError(() => of(null))
                )
            ),
            shareReplay(1),
            filter((val): val is AvailableValidationReport[] | null => val !== "loading")
        );

        this._updateNavigation(null, null, null);
        combineLatest([
            this.getCurrentAccessProject$(),
            this._availableReports$.pipe(startWith([] as AvailableValidationReport[]))
        ])
            .pipe(takeUntil(this._destroyed$))
            .subscribe(([{ group, definition }, reports]) =>
                this._updateNavigation(group, reports, definition)
            );

        this._currentProjectPermissions$ = this._current$.pipe(
            switchMap(({ organizationUri, projectUri }) =>
                this._restApiService
                    .getProjectPermissions(organizationUri, projectUri)
                    .pipe(startWith(null))
            ),
            shareReplay(1)
        );

        this._loader.show("initialization", this._initFinished.asObservable());
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    clearSessionState(): void {
        sessionStorage.removeItem(this._approvalKey);
        sessionStorage.removeItem(this._validityKey);
        sessionStorage.removeItem(this._confirmRegistryKey);
        sessionStorage.removeItem(this._validationReportYearKey);
    }

    canAccessProject(organizationUri: string, projectUri: string): Observable<boolean> {
        return this.getAccessGroup(organizationUri, projectUri).pipe(map(res => !!res));
    }

    // warning: this call may mess with currently selected project (if the parameters identify child project, we don't have its data in session,
    // and comingFromUri is specified). It should be really used just from the SurveyProjectGuard
    isValidChildProject(
        organizationUri: string,
        projectUri: string,
        comingFromUri: string | null
    ): Observable<boolean | "agreement"> {
        return this.getAccessGroup(organizationUri, projectUri).pipe(
            switchMap(res => {
                if (!res) return of(false);
                if (!res.project.parentUri) return of(true);
                if (this._retrieveParentProjectState(res.project.parentUri) !== null)
                    return of(true);
                if (!comingFromUri) return of(false);
                return this._fetchParentState(res, comingFromUri);
            })
        );
    }

    canOpenProject(organizationUri: string, projectUri: string): Observable<boolean> {
        if (this.isStatementApproved(organizationUri, projectUri)) return of(true);
        return this._restApiService.getProjectStatement(organizationUri, projectUri).pipe(
            map(statement => !(statement?.showMessage ?? true)),
            catchError(() => of(false))
        );
    }

    getLanguage(): string {
        return "nl";
    }

    getNavigation$(): Observable<INavNode[]> {
        return this._navigation;
    }

    /**
     * Loads the project definition and set it as current.
     *
     * @param              organizationUri  [organizationUri description]
     * @param         projectUri       [projectUri description]
     *
     * @return                     [return description]
     */
    setCurrentProject(organizationUri: string, projectUri: string): Observable<ProjectDefinition> {
        return this.canOpenProject(organizationUri, projectUri).pipe(
            tap(access => {
                if (!access) {
                    return;
                }

                this._authService.user$
                    .pipe(first(), takeUntil(this._destroyed$))
                    .subscribe(user => {
                        const org = user.organizations?.find(o => o.mrdmUri === organizationUri);

                        if (org && org.position) {
                            this._matomo.setCustomDimension(
                                this._appConfiguration.configuration!.matomo.positionDimensionId,
                                org.position
                            );
                            this._matomoConfiguration.setUserPosition(org.position);
                        }
                    });
            }),
            switchMap(access => {
                if (!access) return of(null);
                if (this._currentProjectDefinition) this._changingProject.next();
                if (organizationUri !== this._currentOrganizationUri) {
                    this._permissionCache = {};
                }
                this._currentOrganizationUri = organizationUri;
                this._currentProjectUri = projectUri;
                this._currentProjectDefinition = null;
                this._currentProjectParentUri = null;
                return this._restApiService
                    .selectProject(
                        organizationUri,
                        projectUri,
                        this.isStatementApproved(organizationUri, projectUri)
                    )
                    .pipe(switchMap(ok => this._restApiService.reapplyLocks().pipe(map(() => ok))));
            }),
            switchMap(ok =>
                forkJoin([
                    this._restApiService.getProjectDefinition(
                        organizationUri,
                        projectUri,
                        this.getLanguage()
                    ),
                    this.getAccessGroup(organizationUri, projectUri)
                ])
            ),
            tap(([definition, group]) => {
                sessionStorage.removeItem(this._validationReportYearKey);

                if (
                    this._currentOrganizationUri === organizationUri &&
                    this._currentProjectUri === projectUri
                ) {
                    this._currentProjectDefinition = definition;
                    this._currentProjectParentUri = group?.project.parentUri ?? null;
                    this._current.next({
                        organizationUri,
                        projectUri,
                        definition,
                        parentUri: this._currentProjectParentUri
                    });
                }

                this._initFinished.next();
            }),
            map(([definition]) => definition)
        );
    }

    resetCurrentProject(): void {
        this._current.next(null);
    }

    markInitializationDone(): void {
        this._initFinished.next();
    }

    getCurrentAccessGroup$(): Observable<OrganizationProject> {
        return this._current$.pipe(
            switchMap(
                ({ organizationUri, projectUri }) =>
                    // Thanks to the logic we know the ids are always valid, so we should never get null
                    this.getAccessGroup(
                        organizationUri,
                        projectUri
                    ) as Observable<OrganizationProject>
            )
        );
    }

    getCurrentAccessProject$(): Observable<{
        group: OrganizationProject;
        definition: ProjectDefinition;
    }> {
        return this._current$.pipe(
            switchMap(({ organizationUri, projectUri, definition }) =>
                // Thanks to the logic we know the ids are always valid, so we should never get null
                (
                    this.getAccessGroup(
                        organizationUri,
                        projectUri
                    ) as Observable<OrganizationProject>
                ).pipe(
                    map(group => ({
                        group,
                        definition
                    }))
                )
            )
        );
    }

    getChangingProjectDefinition$(): Observable<void> {
        return this._changingProject.asObservable();
    }

    getProjectSelection(): Observable<ProjectSelection> {
        return from(this._appConfiguration.configurationPromise).pipe(
            switchMap(config =>
                this._restApiService.getProjectSelection().pipe(
                    map(response => ({
                        ...response,
                        projects: _.mapValues(response.projects, projects =>
                            _(projects)
                                .map(project => ({
                                    ...project,
                                    active:
                                        config.metadataOverrides?.active == null
                                            ? project.active
                                            : config.metadataOverrides.active,
                                    selectable:
                                        config.metadataOverrides?.selectable == null
                                            ? project.selectable
                                            : config.metadataOverrides.selectable,
                                    uiV1:
                                        config.metadataOverrides?.uiV1 == null
                                            ? project.uiV1
                                            : config.metadataOverrides.uiV1,
                                    uiV2:
                                        config.metadataOverrides?.uiV2 == null
                                            ? project.uiV2
                                            : config.metadataOverrides.uiV2
                                }))
                                .filter(project => project.active && project.uiV2)
                                .value()
                        )
                    })),
                    this.defaultErrorHandler()
                )
            )
        );
    }

    getAccessGroup(
        organizationUri: string,
        projectUri: string
    ): Observable<OrganizationProject | null> {
        return this._selection$.pipe(
            map(selection => {
                const organization = _.find(
                    selection.organizations,
                    o => o.uri === organizationUri
                );
                if (!organization) return null;
                const project = _.find(
                    selection.projects[organizationUri],
                    p => p.uri === projectUri
                );
                if (!project) return null;
                return {
                    organization,
                    project
                };
            }),
            first()
        );
    }

    getCurrentAvailableProjects(): Observable<ProjectMetadata[]> {
        return this._selection$.pipe(
            map(selection =>
                this._currentOrganizationUri
                    ? selection.projects[this._currentOrganizationUri] ?? []
                    : []
            ),
            first()
        );
    }

    getCurrentProductName$(): Observable<string | null> {
        return combineLatest([this._selection$, this.currentProject$]).pipe(
            map(([selection, currentProject]) => {
                const availableProjects =
                    selection.projects[currentProject?.organizationUri ?? ""] ?? [];
                const project = availableProjects.find(p => p.uri === currentProject?.projectUri);
                return project?.product ?? null;
            }),
            distinctUntilChanged()
        );
    }

    isStatementApproved(organizationUri: string, projectUri: string): boolean {
        const approved = sessionStorage.getItem(this._approvalKey);
        const key = `${organizationUri}/${projectUri}`;
        return approved === key;
    }

    isRegistryConfirmed(organizationUri: string, projectUri: string): boolean {
        const confirmed = sessionStorage.getItem(this._confirmRegistryKey);
        const key = `${organizationUri}/${projectUri}`;
        return confirmed === key;
    }

    isCurrentStatementApproved(): Observable<boolean | null> {
        return this.getCurrentAccessGroup$().pipe(
            first(),
            switchMap(group => {
                if (group)
                    return of(this.isStatementApproved(group.organization.uri, group.project.uri));
                return of(null);
            })
        );
    }

    approveStatement(organisationUri: string, projectUri: string): void {
        const key = `${organisationUri}/${projectUri}`;
        sessionStorage.setItem(this._approvalKey, key);
        this._extendValidity();
    }

    confirmRegistry(organisationUri: string, projectUri: string): void {
        const key = `${organisationUri}/${projectUri}`;
        sessionStorage.setItem(this._confirmRegistryKey, key);
        this._extendValidity();
    }

    getValidPageSize(value: null | string | number): number | null {
        const pageSize = value === null ? null : +value;
        if (
            pageSize === null ||
            isNaN(pageSize) ||
            pageSize < MIN_PAGE_SIZE ||
            pageSize > MAX_PAGE_SIZE
        ) {
            return null;
        }
        return pageSize;
    }

    getPagingSize(): number {
        const value = this.getValidPageSize(localStorage.getItem(this._pagingKey));
        if (value === null) {
            this.setPagingSize(DEFAULT_PAGE_SIZE);
            return DEFAULT_PAGE_SIZE;
        }
        return value;
    }

    setPagingSize(value: number): void {
        if (value < 0) value = 1;
        localStorage.setItem(this._pagingKey, "" + value);
    }

    getCurrentProjectDefinition(): ProjectDefinition | null {
        return this._currentProjectDefinition;
    }

    getCurrentProjectPermissions$(): Observable<ProjectPermissions | null> {
        return this._currentProjectPermissions$;
    }

    getProjectPermissionForCurrentOrganization(projectUri: string): Observable<ProjectPermissions> {
        if (!this._currentOrganizationUri) {
            return throwError("No organization selected");
        }
        if (!this._permissionCache[projectUri]) {
            this._permissionCache[projectUri] = this._restApiService
                .getProjectPermissions(this._currentOrganizationUri, projectUri)
                .pipe(shareReplay(1));
        }
        return this._permissionCache[projectUri];
    }

    getSubjectPath(datasetUris: string[], subjectUris: string[]): string[] {
        if (datasetUris.length !== subjectUris.length) throw new Error("Inconsistent parameters");
        const result = _.flatten(_.zip(datasetUris, subjectUris)) as string[];
        result.shift();
        return result;
    }

    getSubjectParts(
        path: string,
        rootDatasetUri: string
    ): { datasetUris: string[]; subjectUris: string[] } {
        if (path === "") return { datasetUris: [], subjectUris: [] };
        if (path[0] !== "/") path = "/" + path;
        path = rootDatasetUri + path;
        const parts = _(path.split("/")).chunk(2).unzip().value();
        return {
            datasetUris: parts[0],
            subjectUris: parts[1]
        };
    }

    getEditSubjectUrl(subjectPathUrl: string, projectUriOverride?: string): string;
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    getEditSubjectUrl(subjectPath: string[], projectUriOverride?: string): string;
    getEditSubjectUrl(url: string | string[], projectUriOverride?: string): string {
        if (typeof url !== "string") url = url.join("/");
        return `/registry/${this._currentOrganizationUri}/${
            projectUriOverride ?? this._currentProjectUri
        }/subject/${url}`;
    }

    getSubjectListUrl(ignoreParent: boolean): string {
        const parentState = ignoreParent ? null : this.getParentState();
        return parentState
            ? parentState.returnToUri
            : `/registry/${this._currentOrganizationUri}/${this._currentProjectUri}/list`;
    }

    getBackToListName(ignoreParent: boolean): string {
        const parentState = ignoreParent ? null : this.getParentState();
        if (parentState) return parentState.returnToName;
        if (this._currentProjectDefinition!.localizations.backToList)
            return this._currentProjectDefinition!.localizations.backToList;
        const rootDataset =
            this._currentProjectDefinition!.datasets.find(
                d => d.name === this._currentProjectDefinition!.rootDataset
            ) ?? null;
        return this._lgTranslate.translate("APP._SubjectEdit.Go_back_to_overview", {
            dataset: rootDataset?.label ?? ""
        });
    }

    getAddSubjectText(
        targetDataset: SurveyDataset | null,
        targetVariable: SurveyVariable | null,
        wide: boolean
    ): string {
        if (targetDataset?.localizations?.addSubject) {
            return targetDataset.localizations?.addSubject;
        }

        const maxLength = wide ? 60 : 30;
        let label = targetDataset?.wordLabel ?? targetVariable?.name ?? "";
        if (label.length > maxLength) {
            const bracketPos = label.indexOf("(");
            if (bracketPos > 0) {
                label = label.substring(0, bracketPos);
            }
        }

        let lc: string;
        if (targetVariable) {
            lc = label
                ? "APP._SubjectEdit.Add_sub_subject_button"
                : "APP._SubjectEdit.Add_sub_subject_button_no_label";
        } else {
            lc = label
                ? "APP._SubjectList.Add_subject_button"
                : "APP._SubjectList.Add_subject_button_no_label";
        }
        return this._lgTranslate.translate(lc, {
            label
        });
    }

    getCopySubjectText(
        targetDataset: SurveyDataset | null,
        targetVariable: SurveyVariable,
        wide: boolean
    ): string {
        if (targetDataset?.localizations?.copySubject)
            return targetDataset.localizations?.copySubject;

        const maxLength = wide ? 60 : 30;
        let label = targetDataset?.wordLabel ?? targetVariable?.name ?? "";
        if (label.length > maxLength) {
            const bracketPos = label.indexOf("(");
            if (bracketPos > 0 && bracketPos <= maxLength) {
                label = label.substring(0, bracketPos);
            } else {
                label = "";
            }
        }

        const lc = label
            ? "APP._SubjectEdit.Copy_sub_subject_button"
            : "APP._SubjectEdit.Copy_sub_subject_button_no_label";
        return this._lgTranslate.translate(lc, {
            label
        });
    }

    getValidationReportsUrl(): string {
        return `/registry/${this._currentOrganizationUri}/${this._currentProjectUri}/validation-reports`;
    }

    convertOldToNewUrl(
        oldFrontendUrl: string
    ): { url: string; section: string | null; externalRegistry: boolean } | null {
        if (!oldFrontendUrl) return null;
        // "mrdm/dlcal-2021/patient/02b8d4ba58d7ea481c963896ab75df051c586e17/patient-followup.followup?scroll=0"
        const parser = /^([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)(?:\/([^?]+))?/;
        const matches = oldFrontendUrl.match(parser);
        if (!matches) return null;
        const subjectUrl = this.getSubjectPath(matches[3].split("."), matches[4].split(".")).join(
            "/"
        );
        const url = `/registry/${matches[1]}/${matches[2]}/subject/${subjectUrl}`;
        const section = matches[5] ? matches[5].split(".").slice(-1)[0] : null;
        const externalRegistry = matches[2] !== this._currentProjectDefinition?.name;
        return {
            url,
            section,
            externalRegistry
        };
    }

    getDefaultControl(): ControlType {
        return this._currentProjectDefinition?.defaultControl ?? ControlType.Select;
    }

    getExternalOptionset(
        datasetUris: string[],
        subjectUris: string[],
        variableName: string,
        search?: string | null | undefined
    ): Observable<ExternalOptionset> {
        if (this._currentOrganizationUri === null || this._currentProjectUri === null)
            throw new Error("No active project");
        return this._restApiService.getExternalOptionset(
            this._currentOrganizationUri,
            this._currentProjectUri,
            datasetUris,
            subjectUris,
            variableName,
            search
        );
    }

    /**
     * Notify the state service about user activity
     */
    markUserActivity(): void {
        this._userActivity.next();
    }

    // catchError<T, O extends ObservableInput<any>>(selector: (err: any, caught: Observable<T>) => O): OperatorFunction<T, T | ObservedValueOf<O>>
    defaultErrorHandler<T>(authorizationOnly = false): OperatorFunction<T, T> {
        return catchError((error: SurveyError) => {
            switch (error.code) {
                case SurveyErrorCode.NotAuthorized:
                    this._router.navigate(["/access-denied"]);
                    return EMPTY;
                case SurveyErrorCode.NotLoggedIn:
                    this._confirmationDialog
                        .alertLc(
                            "APP._Global.Session_expired_title",
                            "APP._Global.Session_expired_text"
                        )
                        .then(() => this._auth0.login());
                    return EMPTY;
                case SurveyErrorCode.NoAccess:
                case SurveyErrorCode.ProjectNotAvailable:
                    if (!authorizationOnly) {
                        const tree = this._router.parseUrl("/select");
                        tree.queryParams["organizationUri"] = this._currentOrganizationUri;
                        tree.queryParams["projectUri"] = this._currentProjectUri;
                        this._router.navigateByUrl(tree);
                        return EMPTY;
                    }
                    break;
            }
            return throwError(error);
        });
    }

    setTitle(title: string): void {
        this._title.setTitle(title);
    }

    setRegistryTitle(substitle: string | null): void {
        if (this._currentProjectDefinition === null) throw new Error("No active project");

        let title = "";

        if (this._currentProjectDefinition.description) {
            title = this._currentProjectDefinition.description;
        } else {
            title = this._currentProjectDefinition.name.toUpperCase().replace("-", " - ");
        }

        if (substitle) title = title + ": " + substitle;
        this._title.setTitle(title);
    }

    getCurrentProjectLogo(): string {
        return this.getImageUrl("logo.png");
    }

    getImageUrl(original: string): string {
        const prefix = `${this._imagePrefix}/${this._currentProjectUri}/`;
        const oldPrefix = `../../assets/`;
        if (original.startsWith(oldPrefix)) original = original.substring(oldPrefix.length);
        return prefix + original;
    }

    replaceImageUrls(original: string): string {
        const prefix = `${this._imagePrefix}/${this._currentProjectUri}/`;
        // Replace legacy code?
        let modifiedText = original.replace(/\.\.\/\.\.\/assets\//gi, prefix);

        // Replace all image URLs and point them to backend API
        modifiedText = modifiedText.replace(/!\[]\((.+)\)/g, (_match, url: string) => {
            if (url != null && !url.includes(prefix)) {
                return "![](" + prefix + url + ")";
            }
            return _match;
        });
        return modifiedText;
    }

    getAvailableReports$(): Observable<AvailableValidationReport[] | null> {
        return this._availableReports$;
    }

    getAvailableReport$(uri: string): Observable<AvailableValidationReport | null> {
        return this._availableReports$.pipe(
            map(reports => {
                return reports?.find(r => r.uri === uri) ?? null;
            })
        );
    }

    storeSessionUri(uriId: string, editUri: string, sectionName: string): void {
        const finalUri = `${editUri}?section=${sectionName}`;
        sessionStorage.setItem(`${this._href}_sessionUri__${uriId}`, finalUri);
    }

    storeValidationReportYear(year: string): void {
        sessionStorage.setItem(this._validationReportYearKey, "" + year);
    }

    getStoredValidationReportYear(): string | null {
        const year = sessionStorage.getItem(this._validationReportYearKey);
        return year || null;
    }

    retrieveSessionUri(uriId: string): null | { editUri: string; sectionName: string } {
        const storedUri = sessionStorage.getItem(`${this._href}_sessionUri__${uriId}`);
        if (storedUri === null) return null;
        const parts = storedUri.match(/^([^?]*)(?:\?section=([^&]*))?/);
        // todo: use returnUri as fallback?
        if (!parts) return null;
        return {
            editUri: parts[1],
            sectionName: parts[2] ?? ""
        };
    }

    saveParentState(section: SurveySection): void {
        forkJoin([
            this.getCurrentAccessProject$().pipe(first()),
            this._availableReports$.pipe(first())
        ]).subscribe(([{ group, definition }, reports]) => {
            const rootDataset =
                definition?.datasets.find(d => d.name === definition?.rootDataset) ?? null;
            this._storeParentProjectState(group.project.uri, {
                name: group.project.label,
                subjectListName: rootDataset?.localizations.subjectList ?? null,
                subjectListUri: `/registry/${group.organization.uri}/${group.project.uri}/list`,
                returnToName: section.definition.label,
                returnToUri: this._router.url,
                reports
            });
        });
    }

    getParentState(): ParentProjectState | null {
        if (this._currentProjectParentUri === null) return null;
        return this._retrieveParentProjectState(this._currentProjectParentUri);
    }

    private _checkValidity(): void {
        const validUntil = sessionStorage.getItem(this._validityKey);
        if (validUntil === null) return;
        if (new Date(validUntil) < new Date()) {
            this.clearSessionState();
        }
        this._extendValidity();
    }

    private _extendValidity(): void {
        const validUntil = new Date(Date.now() + 2 * 60 * 60 * 1000);
        sessionStorage.setItem(this._validityKey, validUntil.toISOString());
    }

    private _updateNavigation(
        group: OrganizationProject | null,
        reports: AvailableValidationReport[] | null,
        definition: ProjectDefinition | null
    ): void {
        const navigation: INavNode[] = [
            {
                id: "select",
                path: "select",
                lid: "APP._Navigation.Select_registry"
            }
        ];

        if (group) {
            const parentState = group.project.parentUri
                ? this._retrieveParentProjectState(group.project.parentUri)
                : null;

            const children: INavNode[] = [];
            const registryUrl = `/registry/${group.organization.uri}/${
                group.project.parentUri && group.project.parentUri.length > 0
                    ? group.project.parentUri
                    : group.project.uri
            }`;
            const listUrl = `${registryUrl}/list`;
            const rootDataset =
                definition?.datasets.find(d => d.name === definition?.rootDataset) ?? null;
            navigation.push({
                path: "",
                name: parentState ? parentState.name : group.project.label,
                children
            });
            const subjectListOverride = parentState
                ? parentState.subjectListName
                : rootDataset?.localizations.subjectList;
            children.push({
                path: parentState ? parentState.subjectListUri : listUrl,
                lid: subjectListOverride ? undefined : "APP._Navigation.Select_subject",
                name: subjectListOverride ?? undefined,
                children: [
                    {
                        id: "subject",
                        name: "Edit",
                        path: "",
                        disabled: true,
                        noBreadcrumb: true
                    }
                ]
            });

            if (parentState) reports = parentState.reports;
            if (reports?.length) {
                children.push({
                    path: `${registryUrl}/validation-reports/select`,
                    lid: "APP._Navigation.Validation_reports",
                    children: _.map(reports, report => ({
                        path: `${registryUrl}/validation-reports/view/${report.uri}`,
                        name: report.title
                    }))
                });
            }

            if (!group || !reports?.length) {
                // since the navigation is asynchronous and the user may have full path, make sure the node is always there
                children.push({
                    id: "validationDetail",
                    path: "",
                    noBreadcrumb: true,
                    disabled: true,
                    hidden: true
                });
            }
        }

        this._navigation.next([
            {
                id: "root",
                path: "",
                noBreadcrumb: true,
                children: navigation
            }
        ]);
    }

    // warning: this call will mess with agreement state
    private _fetchParentState(
        group: OrganizationProject,
        comingFromUri: string
    ): Observable<boolean | "agreement"> {
        // First make sure, that the parent project can be accessed
        return this.canOpenProject(group.organization.uri, group.project.parentUri!).pipe(
            switchMap(canOpen => {
                if (!canOpen) return of("agreement" as const);

                return (
                    this._restApiService
                        // Set the parent project as root
                        .selectProject(group.organization.uri, group.project.parentUri!, true)
                        .pipe(
                            switchMap(res =>
                                res.success
                                    ? forkJoin([
                                          // Fetch the parent group data (for label)
                                          this.getAccessGroup(
                                              group.organization.uri,
                                              group.project.parentUri!
                                          ),
                                          // Fetch the parent project definition
                                          this._restApiService.getProjectDefinition(
                                              group.organization.uri,
                                              group.project.parentUri!,
                                              this.getLanguage()
                                          ),
                                          // Fetch the parent project reports
                                          this._reportApi
                                              .getAvailableReports(
                                                  group.organization.uri,
                                                  group.project.parentUri!
                                              )
                                              .pipe(catchError(() => of(null)))
                                      ])
                                    : of(false as const)
                            ),
                            catchError(() => of(false as const)),
                            map(res => {
                                if (res === false) {
                                    return false;
                                } else {
                                    const [parentGroup, definition, reports] = res;
                                    if (!parentGroup) return false;
                                    // Form the urls and find root dataset
                                    const registryUrl = `/registry/${parentGroup.organization.uri}/${parentGroup.project.uri}`;
                                    const rootDataset =
                                        definition?.datasets.find(
                                            d => d.name === definition?.rootDataset
                                        ) ?? null;
                                    // Check if comingFromUrl refers to a subject
                                    const regex = new RegExp(`^${registryUrl}/subject/([^?]+)?`);
                                    const match = regex.exec(comingFromUri);
                                    if (!match) {
                                        const validationRegex = new RegExp(
                                            `^${registryUrl}/validation-reports/([^?]+)?`
                                        );
                                        const validationMatch = validationRegex.exec(comingFromUri);
                                        if (!validationMatch) return false;
                                        const returnToName = this._lgTranslate.translate(
                                            "APP._Navigation.Validation_reports"
                                        );
                                        this._storeParentProjectState(parentGroup.project.uri, {
                                            name: parentGroup.project.label,
                                            subjectListName:
                                                rootDataset?.localizations.subjectList ?? null,
                                            subjectListUri: `${registryUrl}/list`,
                                            returnToName: returnToName ?? rootDataset?.label ?? "?",
                                            returnToUri: comingFromUri,
                                            reports
                                        });
                                        return true;
                                    } else {
                                        // Find the deepest dataset in the url
                                        const subjectPath = match[1].split("/");
                                        const lastDatasetName =
                                            subjectPath[subjectPath.length - 2] ??
                                            rootDataset?.name;
                                        const targetDataset = definition?.datasets.find(
                                            d => d.name === lastDatasetName
                                        );
                                        let returnToName: string | null = null;
                                        if (targetDataset) {
                                            // If the dataset was found, try to locate the section referred in the url
                                            const tree = this._urlSerializer.parse(comingFromUri);
                                            const sectionName = tree.queryParamMap.get("section");
                                            const targetSection = targetDataset.sections.find(
                                                section => section.name === sectionName
                                            );
                                            if (targetSection) returnToName = targetSection.label;
                                        }
                                        // Create the parent project state as well as we can
                                        this._storeParentProjectState(parentGroup.project.uri, {
                                            name: parentGroup.project.label,
                                            subjectListName:
                                                rootDataset?.localizations.subjectList ?? null,
                                            subjectListUri: `${registryUrl}/list`,
                                            returnToName: returnToName ?? rootDataset?.label ?? "?",
                                            returnToUri: comingFromUri,
                                            reports
                                        });
                                        if (rootDataset) {
                                            this._restoreSessionUrls(
                                                registryUrl,
                                                definition,
                                                rootDataset,
                                                subjectPath
                                            );
                                        }
                                        return true;
                                    }
                                }
                            })
                        )
                );
            })
        );
    }

    // try to restore stored session, that would be otherwise generated by the UI. We assume that if parent
    // state wasn't saved, neither were the urls.
    private _restoreSessionUrls(
        registryUrl: string,
        definition: ProjectDefinition,
        rootDataset: DatasetDefinition,
        subjectPath: string[]
    ): void {
        let url = `${registryUrl}/subject/${subjectPath[0]}`;
        let dataset: DatasetDefinition | undefined = rootDataset;
        subjectPath = subjectPath.slice(1); // remove first part and make a clone
        do {
            for (const section of dataset.sections) {
                if (section.generateLink) {
                    this.storeSessionUri(section.generateLink, url, section.name);
                }
            }
            if (subjectPath.length >= 2) {
                const datasetName = subjectPath.shift();
                const subjectUri = subjectPath.shift();
                url = `${url}/${datasetName}/${subjectUri}`;
                dataset = definition.datasets.find(d => d.name === datasetName);
            } else {
                dataset = undefined;
            }
        } while (dataset);
    }

    private _storeParentProjectState(uri: string, data: ParentProjectState): void {
        const dictionary = this._retrieveParentProjectStateStore();
        dictionary[uri] = data;
        const key = `${this._href}_parentState`;
        sessionStorage.setItem(key, JSON.stringify(dictionary));
    }

    private _retrieveParentProjectState(uri: string): ParentProjectState | null {
        const dictionary = this._retrieveParentProjectStateStore();
        return dictionary[uri] ?? null;
    }

    private _retrieveParentProjectStateStore(): _.Dictionary<ParentProjectState> {
        const key = `${this._href}_parentState`;
        const dictionaryJson = sessionStorage.getItem(key);
        let dictionary: _.Dictionary<ParentProjectState> | null = null;
        if (dictionaryJson) {
            dictionary = JSON.parse(dictionaryJson);
            if ((dictionary as any).__version !== PARENT_STATE_VERSION.toString())
                dictionary = null;
        }
        return (
            dictionary ??
            ({
                ["__version"]: PARENT_STATE_VERSION.toString()
            } as unknown as any)
        );
    }
}
