import {
    SubjectState,
    SubjectStateDataset,
    SubjectVariable,
    VariableValidationType
} from "../../models";
import {
    XmlSubject,
    XmlSubjectDataset,
    XmlSubjectValidationMessageEnum,
    XmlSubjectVariable
} from "../responses";
import { convertDate } from "./convert-date";
import { convertStringType } from "./convert-project-definition-core";
import { convertVariableVisibility } from "./convert-project-definition-enums";

export function convertSubject(subject: XmlSubject, datasetUri: string): SubjectState {
    if (subject.variable === undefined)
        throw Error(`Variables of dataset ${datasetUri} can't be undefined`);

    return {
        uri: subject.name,
        datasetUri,
        author: subject.user,
        created: convertDate(subject.created),
        updated: convertDate(subject.updated),
        organisation: subject.organisation,
        canDelete: subject["can-delete"] !== "0",
        label: convertStringType(subject.label, ""),
        variables: subject.variable.map(v => convertSubjectVariable(v)),
        childrenDatasets: subject.dataset ? subject.dataset.map(d => convertSubjectDataset(d)) : []
    };
}

function convertValidationMessageType(
    type: XmlSubjectValidationMessageEnum
): VariableValidationType {
    switch (type) {
        case XmlSubjectValidationMessageEnum.Error:
            return VariableValidationType.Error;
        case XmlSubjectValidationMessageEnum.Warning:
            return VariableValidationType.Warning;
        case XmlSubjectValidationMessageEnum.Exception:
            return VariableValidationType.Exception;
        case XmlSubjectValidationMessageEnum.Text:
            return VariableValidationType.Text;
        case XmlSubjectValidationMessageEnum.Hidden:
            return VariableValidationType.Hidden;
        default:
            throw new Error("Unknown validation type " + type);
    }
}

function convertSubjectVariable(variable: XmlSubjectVariable): SubjectVariable {
    const validationMessages = (variable.validation ?? [])
        .filter(v => v.status === "0")
        .map(v => ({
            name: v.name,
            type: convertValidationMessageType(v.type!),
            message: convertStringType(v.message, "")
        }));

    let hiddenOptions: null | string[] = null;
    if (variable.option && variable.option.length) {
        hiddenOptions = variable.option.filter(o => o.visible === "0").map(o => o.value);
    }

    return {
        uri: variable.name,
        sectionUri: variable.section,
        visible: variable.visible === "1",
        locked: variable.locked === "1",
        isCardinalityReached:
            variable.isCardinalityReached == null ? null : variable.isCardinalityReached === "1",
        hasCalculation: variable.hasCalculation === "1",
        canCreate: variable["can-create"] === "1",
        valid: variable.status === "1",
        value: convertStringType(variable.value, ""),
        visibility: convertVariableVisibility(variable.visibility),
        validationMessages,
        hiddenOptions
    };
}

export function convertSubjectDataset(dataset: XmlSubjectDataset): SubjectStateDataset {
    const subjects =
        dataset.subject == null || dataset.subject.length === 0
            ? []
            : dataset.subject.map(s => convertSubject(s, dataset.name));

    return {
        name: dataset.name,
        subjects
    };
}
