import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, first, map, switchMap, tap } from "rxjs/operators";
import { Auth0AuthorizationService } from "survey-authorization";
import { convertSurveyError } from "./helpers/convert-survey-error";
import { CreateSessionResponse } from "./responses";
import { SAVE_PROJECT, SurveyError, SurveyErrorCode } from "./survey-rest-api.types";
import { SurveyRestSessionApiService } from "./survey-rest-session-api.service";

// const LocalStorageKey = "SurveyRestApiSession";

// interface LocalStorageEntry {
//     email: string;
//     session: CreateSessionResponse;
// }

type SessionPair = [
    token: string | null,
    session: CreateSessionResponse | null,
    error?: SurveyError
];

@Injectable({ providedIn: "root" })
export class SurveySessionInterceptorService implements HttpInterceptor {
    private _session: CreateSessionResponse | null = null;
    private _lastToken: string | null = null;
    private _session$: Observable<SessionPair>;
    private _projectState: any = null;

    constructor(
        _authService: Auth0AuthorizationService,
        private _sessionApi: SurveyRestSessionApiService
    ) {
        this._session$ = of(true).pipe(
            concatMap(() => _authService.getTokenSilently()),
            first(),
            catchError(() => of(null)),
            switchMap((token: string | null): Observable<SessionPair> => {
                if (token === this._lastToken && this._session !== null) {
                    return of([token, this._session]);
                }

                this._session = null;
                this._lastToken = token;
                if (token === null) {
                    return of([
                        null,
                        null,
                        { code: SurveyErrorCode.NotLoggedIn, error: "Not logged in" }
                    ]);
                }

                return _sessionApi.createSession(token).pipe(
                    tap(session => {
                        this._session = session;
                    }),
                    switchMap(session => {
                        if (this._projectState === null) {
                            return of(session);
                        } else {
                            return _sessionApi.reapplyProject(this._projectState).pipe(
                                switchMap(() => this._sessionApi.reapplyLocks()),
                                map(() => session)
                            );
                        }
                    }),
                    map(session => [token, session])
                );
            }),
            catchError(error => of([null, null, convertSurveyError(error)] as SessionPair))
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let previousSession: CreateSessionResponse | null = null;
        const saveProject = req.context.get(SAVE_PROJECT);
        if (saveProject) {
            this._projectState = this._sessionApi.extractProject(req.body);
        }
        return this._session$.pipe(
            concatMap(([token, session, error]) => {
                previousSession = session;
                // session = {
                //     sessionId: "1nsn21j38lokk1iampkqcb8chcrs2i19d-phrew18bs5bi",
                //     "X-Session-Key": "Survey-b322156559c2a5f435eac0e647bc6fd721e56f65",
                //     "X-Session-Token":
                //         "64e9351998f5cd56898abfe78f7150f971d45532-88c4e8a7880b49fbe7b24050fc6fc16b97640d9f-f7f154fcca96ea59a296ebb59924af0eac138eaa-27d297b0ee45c79dea36d36acec19c421c43fb91-4b1f6ff586d6e8436e2ce72ebc312e1b9a1ce1fd"
                // };
                if (error) throw error;
                const modifiedRequest = this._injectSessionData(req, token, session);
                return next.handle(modifiedRequest);
            }),
            catchError(error => this._remapError(error)),
            catchError(error => {
                if (error.status === 403 && this._lastToken !== null) {
                    // We had 403 error, try again with new session
                    // Remove current session, unless other request already changed it
                    if (this._session === previousSession) {
                        this._session = null;
                    }
                    // And retry
                    return this._session$.pipe(
                        concatMap(([token, session]) => {
                            const modifiedRequest = this._injectSessionData(req, token, session);
                            return next.handle(modifiedRequest);
                        }),
                        catchError(error2 => this._remapError(error2))
                    );
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private _injectSessionData(
        req: HttpRequest<any>,
        token: string | null,
        session: CreateSessionResponse | null
    ): HttpRequest<any> {
        if (session === null || token === null) return req;
        return req.clone({
            headers: req.headers
                .set("Authorization", `Bearer ${token}`)
                .set("X-Session-Key", session["X-Session-Key"])
                .set("X-Session-Token", session["X-Session-Token"]),
            url: req.url.replace("sessionId", session.sessionId)
        });
    }

    // Convert the 500 session mismatch into another 403 error
    private _remapError(
        errorResponse: HttpErrorResponse | SurveyError
    ): Observable<HttpEvent<any>> {
        if ("code" in errorResponse) return throwError(errorResponse);

        if (
            errorResponse.status === 500 &&
            errorResponse.error?.error === "Session is not owned by bearer token user"
        ) {
            return throwError(
                new HttpErrorResponse({
                    headers: errorResponse.headers,
                    status: 403,
                    statusText: "Forbidden",
                    error: { error: "Access denied" },
                    url: errorResponse.url ?? undefined
                })
            );
        }
        return throwError(errorResponse);
    }

    // private getStoredSession(user: Auth0User): CreateSessionResponse | null {
    //     const entry: LocalStorageEntry = localStorage[LocalStorageKey];
    //     if (!entry || entry.email !== user.email) return null;
    //     return entry.session;
    // }

    // private storeSession(user: Auth0User, session: CreateSessionResponse): void {
    //     localStorage[LocalStorageKey] = {
    //         email: user.email,
    //         session
    //     };
    // }
}
