export interface SearchResultSubject {
    subjectUris: string[];
    datasetUris: string[];
    variableName: string;
    organizationUri: string;
    label: string;
    otherOrganization: boolean;
}

export interface SearchResult {
    subjects: SearchResultSubject[];
    total: number;
}
