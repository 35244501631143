import { Inject, Injectable } from "@angular/core";
import { IApplicationEventTracer, LG_APPLICATION_EVENT_TRACER } from "@logex/framework/core";
import { ApplicationInsightsConfigurationService } from "./application-insights-configuration.service";

const Category = "Survey";

@Injectable({
    providedIn: "root"
})
export class SurveyEventTracer {
    constructor(
        @Inject(LG_APPLICATION_EVENT_TRACER) private _tracer: IApplicationEventTracer,
        private _aic: ApplicationInsightsConfigurationService
    ) {}

    setProject(organizationUri: string | null, projectUri: string | null): void {
        this._aic.setProject(organizationUri, projectUri);
    }

    deleteSubject(datasetUris: string[], subjectUris: string[]): void {
        this._tracer.trackEvent(Category, "deleteSubject", datasetUris.join("/"));
    }

    createSubject(
        parentDatasetUris: string[],
        parentSubjectUris: string[],
        datasetUri: string
    ): void {
        this._tracer.trackEvent(
            Category,
            "createSubject",
            parentDatasetUris ? [...parentDatasetUris, datasetUri].join("/") : datasetUri
        );
    }

    searchOpened(): void {
        this._tracer.trackEvent(Category, "searchOpened");
    }

    searchExecuted(searchTerm: string): void {
        this._tracer.trackEvent(Category, "searchExecuted");
    }

    searchSelectedSubject(subject: string): void {
        this._tracer.trackEvent(Category, "searchSelectSubject", subject);
    }

    externalOptionsetOpened(datasetUris: string[], variable: string): void {
        this._tracer.trackEvent(
            Category,
            "externalOptionsetOpened",
            `${datasetUris.join("/")}:${variable}`
        );
    }

    externalOptionsetSelected(
        datasetUris: string[],
        variable: string,
        optionsetRowId: string
    ): void {
        this._tracer.trackEvent(
            Category,
            "externalOptionsetSelect",
            `${datasetUris.join("/")}:${variable}:${optionsetRowId}`
        );
    }

    externalOptionsetCleared(datasetUris: string[], variable: string): void {
        this._tracer.trackEvent(
            Category,
            "externalOptionsetCleared",
            `${datasetUris.join("/")}:${variable}`
        );
    }
}
