import * as _ from "lodash";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
    ApplicationInsightsCustomData,
    IApplicationInsightsConfiguration
} from "@logex/framework/lg-application";

import { ConfigService } from "./config.service";
import { AppConfiguration } from "./app-configuration";

@Injectable({ providedIn: "root" })
export class ApplicationInsightsConfigurationService implements IApplicationInsightsConfiguration {
    autoInit = true;
    instrumentationKey = "";
    readonly userId = new BehaviorSubject<string | null>(null);
    correlationHeaderExcludedDomains: string[] | undefined;

    private _organizationUri: string | null = null;
    private _projectUri: string | null = null;
    private _config?: AppConfiguration;

    constructor(private _appConfiguration: ConfigService) {
        // empty
        _appConfiguration.configurationPromise.then(value => {
            this._config = value;
            this.instrumentationKey = value.applicationInsights.instrumentationKey ?? "";
            this.correlationHeaderExcludedDomains =
                value.applicationInsights.correlationHeaderExcludedDomains;
        });
    }

    doNotDoAiTracking(): boolean {
        return this._appConfiguration.getDoNotTrack() || !this.instrumentationKey;
    }

    setUserID(id: string): void {
        this.userId.next(id);
    }

    setProject(organizationUri: string | null, projectUri: string | null): void {
        this._organizationUri = organizationUri;
        this._projectUri = projectUri;
    }

    customData(): _.Dictionary<string | null> {
        return {
            [ApplicationInsightsCustomData.Environment]: this._config?.environment ?? "unknown",
            ["organizationUri"]: this._organizationUri,
            ["projectUri"]: this._projectUri
        };
    }
}
